angular.module("app.tpls", ["partials/mms/about.html", "partials/mms/add-pe.html", "partials/mms/cancelConfirm.html", "partials/mms/cancelModal.html", "partials/mms/confirmDelete.html", "partials/mms/confirmRemove.html", "partials/mms/docCover.html", "partials/mms/full-doc.html", "partials/mms/login.html", "partials/mms/manage-refs.html", "partials/mms/new-branch.html", "partials/mms/new-doc-or-group.html", "partials/mms/new-tag.html", "partials/mms/new-view.html", "partials/mms/pane-center.html", "partials/mms/pane-left.html", "partials/mms/pane-right.html", "partials/mms/printConfirm.html", "partials/mms/redirect.html", "partials/mms/reorder-groups.html", "partials/mms/reorder-views.html", "partials/mms/select.html", "partials/mms/selectModal.html", "partials/mms/tableExport.html", "partials/mms/veFooter.html", "partials/mms/veMenu.html", "partials/mms/veNav.html"]);

angular.module("partials/mms/about.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/about.html",
    "<div class=\"modal-header\">\n" +
    "    <h4>About</h4>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-body\">\n" +
    "    <p>MMS Version : <b>{{mmsV}}</b></p>\n" +
    "    <p>View Editor Version : <b>{{veV}}</b></p>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Close</button>\n" +
    "</div>\n" +
    "");
}]);

angular.module("partials/mms/add-pe.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/add-pe.html",
    "<!-- TODO: This is almost the same as new-view.html.  Should be combined if possible -->\n" +
    "\n" +
    "<div class=\"modal-header\">\n" +
    "    <h4 class=\"pe-type-{{presentationElemType}}\">Add {{presentationElemType | lowercase}}</h4>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-body\">\n" +
    "    <div class=\"ve-light-tabs modal-top-tabs\">\n" +
    "        <ul class=\"nav nav-tabs\" ng-show=\"presentationElemType != 'Comment'\">\n" +
    "            <li class=\"uib-tab nav-item tab-item\" ng-class=\"{'active': createForm}\">\n" +
    "                <a class=\"nav-link\" ng-click=\"createForm = true\"><i class=\"fa fa-plus\"></i>Create New</a>\n" +
    "            </li>\n" +
    "            <li class=\"uib-tab nav-item tab-item\" ng-class=\"{'active': !createForm}\">\n" +
    "                <a class=\"nav-link\" ng-click=\"createForm = false\"><i class=\"fa fa-search\"></i>Search for existing</a>\n" +
    "            </li>\n" +
    "        </ul>\n" +
    "    </div>\n" +
    "\n" +
    "    <div ng-show=\"createForm\">\n" +
    "        <div class=\"form-group\">\n" +
    "            <label>Name (optional):</label>\n" +
    "            <input class=\"form-control\" ng-model=\"newPe.name\" ng-keyup=\"$event.keyCode == 13 ? ok() : null\" type=\"text\" placeholder=\"Type a name for your {{presentationElemType}} here\"\n" +
    "                autofocus>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div ng-show=\"!createForm\">\n" +
    "        <mms-search mms-options=\"searchOptions\" mms-project-id=\"{{viewOrSectionOb._projectId}}\" mms-ref-id=\"{{viewOrSectionOb._refId}}\"></mms-search>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-primary\" ng-show=\"createForm\" ng-click=\"ok()\">Create\n" +
    "        <i ng-show=\"oking\" class=\"fa fa-spin fa-spinner\"></i>\n" +
    "    </button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "</div>");
}]);

angular.module("partials/mms/cancelConfirm.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/cancelConfirm.html",
    "<div class=\"modal-header\">\n" +
    "    <h4>Discard Modifications</h4>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-body\">\n" +
    "    <p>\n" +
    "      There are unsaved modifications to this element, which may include html beautification.<br/>\n" +
    "      Are you sure you want to discard them? \n" +
    "    </p>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-warning \" ng-click=\"ok()\">Discard</button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "</div>\n" +
    "");
}]);

angular.module("partials/mms/cancelModal.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/cancelModal.html",
    "<div class=\"assistModal\">\n" +
    "    <div class=\"modal-body\">\n" +
    "        <h3>Are you sure you want to exit this page?</h3>\n" +
    "        <br>\n" +
    "        <div>\n" +
    "            Your changes will not be saved if you leave this page, but you may come back to this page later using the\n" +
    "            same link.\n" +
    "        </div>\n" +
    "        <br>\n" +
    "        <span style=\"text-align: right; width: 100%; display: inline-block;\">\n" +
    "            <button class=\"btn btn-warning\" ng-click=\"exit()\">Leave page</button>\n" +
    "            <button class=\"btn btn-default\" ng-click=\"close()\">Stay on page</button>\n" +
    "        </span>\n" +
    "    </div>\n" +
    "</div>");
}]);

angular.module("partials/mms/confirmDelete.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/confirmDelete.html",
    "<div class=\"modal-header\">\n" +
    "    <h4>Confirm Delete</h4>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-body\">\n" +
    "    <p>Are you sure you want to delete {{type}} <b>&ldquo;{{name}}&rdquo;</b>?</p>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-warning\" ng-click=\"ok()\">Delete <i ng-show=\"oking\" class=\"fa fa-spin fa-spinner\"></i></button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "</div>");
}]);

angular.module("partials/mms/confirmRemove.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/confirmRemove.html",
    "<div class=\"modal-header\">\n" +
    "    <h4>Confirm Remove</h4>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-body\">\n" +
    "    <p>Are you sure you want to remove {{type}} <b>&ldquo;{{name}}&rdquo;</b>?</p>\n" +
    "    <p ng-if=\"type != 'Document' && type != 'group'\">This will remove <b>&ldquo;{{name}}&rdquo;</b> from the hierarchy, but you can still access it in search.</p>\n" +
    "    <p ng-if=\"type === 'Document' || type === 'group'\">This will remove <b>&ldquo;{{name}}&rdquo;</b> from the project navigation, but you can still access it in search.</p>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-warning\" ng-click=\"ok()\">Remove <i ng-show=\"oking\" class=\"fa fa-spin fa-spinner\"></i></button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "</div>");
}]);

angular.module("partials/mms/docCover.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/docCover.html",
    "<div>\n" +
    "    <div style=\"page-break-after:always\">\n" +
    "        <div style=\"text-align:center; font-size:32px\">{{meta.project}}</div>\n" +
    "        <div style=\"text-align:center; font-size:30px\">{{meta.title}}</div>\n" +
    "        <div style=\"text-align:center; font-size:12px\"> {{meta.version}} {{tag.name}}</div>\n" +
    "        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>\n" +
    "\n" +
    "        <div> Paper copies of this document may not be current and should not be relied on for official purposes.</div>\n" +
    "        <div> {{meta.titlelegal}}</div>\n" +
    "        <div> {{time | date:'M/d/yy h:mm a'}}</div>\n" +
    "        <div> {{meta.dnumber}}</div>\n" +
    "        <br/><br/>\n" +
    "        <div ng-if=\"meta.instlogo\"><img ng-src=\"{{meta.instlogo}}\"></div>\n" +
    "        <div> {{meta.inst1}}</div>\n" +
    "        <div> {{meta.inst2}}</div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div style=\"page-break-after:always\">\n" +
    "        <div style=\"font-size:32px\" ng-if=\"meta.project\">{{meta.project}}</div>\n" +
    "        <div style=\"font-size:30px\">{{meta.title}}</div>\n" +
    "        <div style=\"font-size:12px\"><span ng-if=\"meta.version\">{{meta.version}}</span> {{tag.name}}</div>\n" +
    "        \n" +
    "        <div ng-if=\"meta.authors.length > 0\">\n" +
    "            <br/><br/>\n" +
    "            <div>PREPARED BY:</div>\n" +
    "            <div ng-repeat=\"author in meta.authors\">\n" +
    "                <br/><br/>\n" +
    "                <div><span>_____________________________________</span> <span class=\"pull-right\">________________</span></div>\n" +
    "                <div><span>{{author.firstname}} {{author.lastname}}</span><span class=\"pull-right\">Date</span></div>\n" +
    "                <div>{{author.title}}, {{author.orgname}} ({{author.orgnum}})</div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-if=\"meta.approvers.length > 0\"> \n" +
    "            <br/><br/>\n" +
    "            <div>APPROVED BY:</div>\n" +
    "            <div ng-repeat=\"author in meta.approvers\">\n" +
    "                <br/><br/>\n" +
    "                <div><span>_____________________________________</span> <span class=\"pull-right\">________________</span></div>\n" +
    "                <div><span>{{author.firstname}} {{author.lastname}}</span><span class=\"pull-right\">Date</span></div>\n" +
    "                <div>{{author.title}}, {{author.orgname}} ({{author.orgnum}})</div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-if=\"meta.concurrences.length > 0\">\n" +
    "            <br/><br/>\n" +
    "            <div>CONCURRED BY:</div>\n" +
    "            <div ng-repeat=\"author in meta.concurrences\">\n" +
    "                <br/><br/>\n" +
    "                <div><span>_____________________________________</span> <span class=\"pull-right\">________________</span></div>\n" +
    "                <div><span>{{author.firstname}} {{author.lastname}}</span><span class=\"pull-right\">Date</span></div>\n" +
    "                <div>{{author.title}}, {{author.orgname}} ({{author.orgnum}})</div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <br/><br/>\n" +
    "        <div ng-if=\"meta.instlogo\"> <img ng-src=\"{{meta.instlogo}}\"></div>\n" +
    "        <div> {{meta.inst1}}</div>\n" +
    "        <div> {{meta.inst2}}</div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div style=\"page-break-after:always\">\n" +
    "        <div ng-if=\"meta.revisions.length > 0\"> \n" +
    "            <div style=\"text-align:center\"><b>Change Log</b></div>\n" +
    "            <table>\n" +
    "                <thead>\n" +
    "                    <tr><td>Version</td><td>Date</td><td>Sections Changed</td><td>Author</td></tr>\n" +
    "                </thead>\n" +
    "                <tbody>\n" +
    "                    <tr ng-repeat=\"rev in meta.revisions\"><td>{{rev.revnum}}</td><td>{{rev.date}}</td><td>{{rev.remark}}</td><td>{{rev.firstname}} {{rev.lastname}}</td></tr>\n" +
    "                </tbody>\n" +
    "            </table>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-if=\"meta.emails.length > 0\">\n" +
    "            <br/>\n" +
    "            <div style=\"text-align:center\"><b>Distribution List</b></div>\n" +
    "            <div ng-repeat=\"email in meta.emails\">{{email}}</div>\n" +
    "        </div>\n" +
    "\n" +
    "        <br/><br/><br/><br/><br/><br/><br/><br/>\n" +
    "        <div ng-if=\"meta.instlogo\"> <img ng-src=\"{{meta.instlogo}}\"></div>\n" +
    "        <div> {{meta.inst1}}</div>\n" +
    "        <div> {{meta.inst2}}</div>\n" +
    "    </div>\n" +
    "</div>");
}]);

angular.module("partials/mms/full-doc.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/full-doc.html",
    "<div ng-if=\"search === null\">\n" +
    "    <div fa-pane pane-anchor=\"north\" pane-size=\"36px\" pane-no-toggle=\"true\" pane-no-scroll=\"true\">\n" +
    "        <div class=\"pane-center-toolbar\">\n" +
    "            <div class=\"doclib-link\">\n" +
    "                <a type=\"button\" class=\"btn btn-tools btn-sm\" target=\"_blank\" rel=\"noopener noreferrer\" ng-href=\"{{docLibLink}}\" uib-tooltip=\"Manage files\" tooltip-placement=\"bottom\" tooltip-popup-delay=\"100\">\n" +
    "                    <img src=\"assets/alfresco-icon-sm.png\">DocLib\n" +
    "                </a>\n" +
    "                <button type=\"button\" class=\"btn btn-tools btn-sm share-url\" ng-if=\"!ve_fn\" ng-click=\"handleShareURL()\" uib-tooltip=\"Share Page\" tooltip-placement=\"bottom\" tooltip-popup-delay=\"100\"\n" +
    "                        popover-trigger=\"outsideClick\" uib-popover-template=\"dynamicPopover.templateUrl\" popover-title=\"{{dynamicPopover.title}}\" popover-placement=\"bottom-left\">\n" +
    "                    <i class=\"fa fa-share-alt\"></i></button>\n" +
    "            </div>\n" +
    "            <div class=\"pane-center-btn-group\">\n" +
    "                <mms-button-bar buttons=\"buttons\" mms-bb-api=\"bbApi\" class=\"bordered-button-bar\"></mms-button-bar>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div fa-pane pane-anchor=\"center\" pane-scroll-api=\"scrollApi\">\n" +
    "        <i class=\"pane-center-spinner fa fa-5x fa-spinner fa-spin\" ng-show=\"ve_viewContentLoading\"></i>\n" +
    "        <div class=\"ve-notify-banner\" ng-show=\"refOb.type === 'Tag'\">\n" +
    "            <span><strong>Tags are read only:</strong> Switch to a branch to edit</span>\n" +
    "        </div>\n" +
    "        <div class=\"container-fluid ve-secondary-text\"> Document Last Modified: {{ latestElement | date:'M/d/yy h:mm a' }}</div>\n" +
    "        <div class=\"pane-center container-fluid\" id=\"print-div\">\n" +
    "            <div ng-repeat=\"view in views track by view.id\" ng-class=\"{chapter: view.topLevel, 'first-chapter': view.first}\">\n" +
    "                <mms-view mms-element-id=\"{{view.id}}\" mms-commit-id=\"latest\" mms-project-id=\"{{projectOb.id}}\" mms-ref-id=\"{{refOb.id}}\" mms-number=\"{{view.number}}\" mms-view-api=\"view.api\"></mms-view>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div ng-if=\"search !== null\">\n" +
    "    <i class=\"pane-center-spinner fa fa-5x fa-spinner fa-spin\" ng-show=\"ve_viewContentLoading\"></i>\n" +
    "    <mms-search mms-options=\"searchOptions\" mms-project-id={{projectOb.id}} mms-ref-id=\"{{refOb.id}}\"></mms-search>\n" +
    "</div>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"shareUrlTemplate.html\">\n" +
    "    <p id=\"ve-short-url\">\n" +
    "        {{(shortUrl)}}\n" +
    "    </p>\n" +
    "    <button ng-click=\"copyToClipboard($event)\" class=\"btn btn-sm btn-default\"><i class=\"fa fa-copy\"></i>Copy</button>\n" +
    "</script>\n" +
    "");
}]);

angular.module("partials/mms/login.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/login.html",
    "<div id=\"ve-login\" class=\"row\">\n" +
    "    <div class=\"account-wall\">\n" +
    "        <div>\n" +
    "            <h2>{{ pageTitle }}</h2>\n" +
    "        </div>\n" +
    "        <div>\n" +
    "            <form name=\"loginForm\" ng-submit=\"login(credentials)\">\n" +
    "                <span class=\"label-text\">Username:</span>\n" +
    "                <input type=\"text\" class=\"form-control login-input\" ng-model=\"credentials.username\" autofocus>\n" +
    "                <span class=\"label-text\">Password:</span>\n" +
    "                <input type=\"password\" class=\"form-control login-input\" ng-model=\"credentials.password\">\n" +
    "                <button class=\"btn btn-block btn-primary\" type=\"submit\">Log In\n" +
    "                    <span ng-if=\"spin\" ><i class=\"fa fa-spin fa-spinner\"></i></span>\n" +
    "                </button>\n" +
    "            </form>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("partials/mms/manage-refs.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/manage-refs.html",
    "<div class=\"container-fluid ve-no-panes\">\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-md-10 col-md-offset-1\">\n" +
    "            <a class=\"back-to-docs\" ui-sref=\"project.ref({refId: fromParams.id, search: undefined})\"\n" +
    "               ui-sref-opts=\"{reload:true}\">Back to Project Documents ({{fromParams.name}})</a>\n" +
    "            <h1 class=\"panel-title\">Manage Project branches/tags</h1>\n" +
    "            <div class=\"panel panel-default\">\n" +
    "                <div class=\"panel-body no-padding-panel\">\n" +
    "                    <div ng-show=\"refManageView\" class=\"col-md-4 ve-light-list-panels\">\n" +
    "                        <ul class=\"ve-light-list\">\n" +
    "                            <li class=\"ve-light-input\">\n" +
    "                                <input placeholder=\"Filter branches/tags\" class=\"ve-plain-input\" ng-model=\"refFilter\">\n" +
    "                            </li>\n" +
    "                            <li class=\"ref-title\">\n" +
    "                                <h2><i class=\"fa fa-code-fork\" aria-hidden=\"true\"></i>Branches</h2>\n" +
    "                            </li>\n" +
    "                            <li class=\"ref-item\" ng-repeat=\"branch in branches | orderBy:'name' | filter:{name:refFilter}\" ng-click=\"refClickHandler(branch)\"\n" +
    "                                ng-class=\"{'selected': branch.id === refSelected.id}\">\n" +
    "                                <a>{{ branch.name }}</a>\n" +
    "                            </li>\n" +
    "                            <li class=\"ref-title\">\n" +
    "                                <h2><i class=\"fa fa-tag\" aria-hidden=\"true\"></i>Tags</h2>\n" +
    "                            </li>\n" +
    "                            <li class=\"ref-item\" ng-repeat=\"tag in tags | orderBy:'name' | filter:{name:refFilter}\" ng-click=\"refClickHandler(tag)\"\n" +
    "                                ng-class=\"{'selected': tag.id === refSelected.id}\">\n" +
    "                                <a>{{ tag.name }}</a>\n" +
    "                            </li>\n" +
    "                            <li ng-if=\"!tags.length\" class=\"ve-secondary-text\">No Tags</li>\n" +
    "                        </ul>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-md-8 ve-light-panels-detail\" ng-show=\"refSelected\">\n" +
    "                        <div class=\"panels-detail-title clearfix\">\n" +
    "                            <h3 class=\"{{refSelected.type}}-icon\">{{refSelected.name}}</h3>\n" +
    "                            <div class=\"ref-button-options\" style=\"float:right\">\n" +
    "                            <button class=\"btn btn-default\" ng-disabled=\"refSelected.status === 'creating'\" type=\"button\" ng-click=\"deleteRef()\" ng-if=\"refSelected.id != 'master'\"><i class=\"fa fa-trash\"></i> Delete</button>\n" +
    "                            <button class=\"btn btn-primary\" ng-disabled=\"refSelected.status === 'creating'\" type=\"button\" ng-click=\"addTag()\"><i class=\"fa fa-plus\"></i> Tag</button>\n" +
    "                            <button class=\"btn btn-primary\" ng-disabled=\"refSelected.status === 'creating'\" type=\"button\" ng-click=\"addBranch()\"><i class=\"fa fa-plus\"></i> Branch</button>\n" +
    "                            </div>\n" +
    "                            <!-- <mms-button-bar buttons=\"buttons\" mms-bb-api=\"bbApi\"></mms-button-bar> -->\n" +
    "                        </div>\n" +
    "                        <dl class=\"dl-horizontal ve-light-panels-detail-content\">\n" +
    "                            <dt></dt>\n" +
    "                            <dd ng-hide=\"refSelected.status === 'creating'\" class=\"link-section\">\n" +
    "                                <a ui-sref=\"project.ref({refId: refSelected.id, search: undefined})\" ui-sref-opts=\"{reload:true}\">Project Documents</a>\n" +
    "                            </dd>\n" +
    "                            <dd ng-show=\"refSelected.status === 'creating'\" class=\"link-section\">\n" +
    "                                <span uib-tooltip-html=\"htmlTooltip\" tooltip-placement=\"top\" tooltip-trigger=\"mouseenter\"\n" +
    "                                    tooltip-append-to-body=\"refSelected.status == 'creating'\" tooltip-enable=\"refSelected.status == 'creating'\"\n" +
    "                                    ng-class=\"{'branch-disabled': refSelected.status == 'creating'}\">Project Documents</span>\n" +
    "                            </dd>\n" +
    "                            <dt>Id</dt>\n" +
    "                            <dd>{{refSelected.id}}</dd>\n" +
    "                            <dt>Type</dt>\n" +
    "                            <dd>{{refSelected.type}}</dd>\n" +
    "                            <dt>Description</dt>\n" +
    "                            <dd>{{refSelected.description}}</dd>\n" +
    "                            <span ng-if=\"refSelected.id != 'master'\">\n" +
    "                            <dt>Time Created</dt>\n" +
    "                            <dd>{{refSelected._created}}</dd>\n" +
    "                            <dt>Creator</dt>\n" +
    "                            <dd>{{refSelected._creator}}</dd>\n" +
    "                            <!-- <dt>Last Modified</dt>\n" +
    "                            <dd>{{refSelected._modified}}</dd> -->\n" +
    "                            <dt>Modifier</dt>\n" +
    "                            <dd>{{refSelected._modifier}}</dd>\n" +
    "                            <dt>Parent Ref</dt>\n" +
    "                            <dd>{{refSelected.parentRefId}}</dd>\n" +
    "                            </span>\n" +
    "                        </dl>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>");
}]);

angular.module("partials/mms/new-branch.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/new-branch.html",
    "<div class=\"modal-header\">\n" +
    "    <h4 class=\"branch-icon\">Create new branch from {{from}}</h4>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-body ve-new-ref\">\n" +
    "    <p class=\"modal-description\">A <b>branch</b> is a copy of your project for you to work on specific tasks or sandbox ideas, without interfering with other copies.\n" +
    "        <a ui-sref=\"project.ref.document.view({documentId: '_18_0_2_8630260_1446850132083_177552_51111', viewId: 'MMS_1453977130045_239e2aee-1243-4480-a6f8-61ff7bed700f', projectId: 'PROJECT-ID_10_15_15_1_41_52_PM_5b84f7be_1506a83819c__6bce_cae_tw_jpl_nasa_gov_128_149_19_85', refId: 'master', search: undefined})\">more</a></p>\n" +
    "    <div class=\"form-group\">\n" +
    "        <label>Name</label>\n" +
    "        <input class=\"form-control\" ng-model=\"branch.name\" type=\"text\"\n" +
    "            placeholder=\"Type a name for your branch here\" autofocus>\n" +
    "    </div>\n" +
    "    <div class=\"form-group\">\n" +
    "        <label>Description</label>\n" +
    "        <textarea class=\"form-control\" ng-model=\"branch.description\"></textarea>\n" +
    "    </div>\n" +
    "    <div class=\"form-group\">\n" +
    "        <label>Permission</label>\n" +
    "        <br />\n" +
    "        <input ng-model=\"branch.permission\" value=\"read\" type=\"radio\"> Read\n" +
    "        <br />\n" +
    "        <input ng-model=\"branch.permission\" value=\"write\" type=\"radio\"> Write\n" +
    "    </div>\n" +
    "    <div class=\"form-group\">\n" +
    "        <label>Point in History</label>\n" +
    "        <form>\n" +
    "            <div class=\"radio radio-with-label\">\n" +
    "                <label for=\"most-recent\">\n" +
    "                    <input id=\"most-recent\" type=\"radio\" name=\"most recent\" ng-model=\"branch.lastCommit\" ng-value=\"true\">Most Recent\n" +
    "                </label><br>\n" +
    "                <label for=\"specify-point\">\n" +
    "                    <input id=\"specify-point\" type=\"radio\" name=\"specify point\" ng-model=\"branch.lastCommit\" ng-value=\"false\">Specify a timestamp\n" +
    "                </label>\n" +
    "                <div class=\"indent ve-secondary-text timestamp-format\">\n" +
    "                    <i>(YYYY-MM-DDTHH:MM:SS)</i>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"indent\">\n" +
    "                <input class=\"datetimepicker\" id=\"branch\">\n" +
    "            </div>\n" +
    "        </form>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-primary\" ng-click=\"ok()\">Create <i ng-show=\"oking\" class=\"fa fa-spin fa-spinner\"></i></button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "</div>");
}]);

angular.module("partials/mms/new-doc-or-group.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/new-doc-or-group.html",
    "<div class=\"modal-header\">\n" +
    "    <h4 class=\"item-type-{{itemType}}\">Create new {{itemType | lowercase}}</h4>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-body comment-modal\">\n" +
    "\n" +
    "    <div class=\"comment-modal-input\">\n" +
    "        <div class=\"form-group\">\n" +
    "            <label>Name:</label>\n" +
    "            <input ng-if=\"itemType === 'Document'\" class=\"form-control\" ng-model=\"newDoc.name\" type=\"text\"\n" +
    "                ng-keyup=\"$event.keyCode == 13 ? ok() : null\" placeholder=\"Type a name for your document here\" autofocus>\n" +
    "            <input ng-if=\"itemType === 'Group'\"class=\"form-control\" ng-model=\"newGroup.name\" type=\"text\"\n" +
    "                ng-keyup=\"$event.keyCode == 13 ? ok() : null\" placeholder=\"Type a name for your group here\" autofocus>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-primary\" ng-click=\"ok()\">Create <i ng-show=\"oking\" class=\"fa fa-spin fa-spinner\"></i>\n" +
    "    </button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "</div>");
}]);

angular.module("partials/mms/new-tag.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/new-tag.html",
    "<div class=\"modal-header\">\n" +
    "    <h4 class=\"tag-icon\">Create new tag</h4>\n" +
    "    <span class=\"ve-notify-banner\">Tags are read only</span>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-body ve-new-ref\">\n" +
    "    <p class=\"modal-description\">A <b>tag</b> is a read-only version of your project at a specific moment in time, typically a release or review.\n" +
    "        <a ui-sref=\"project.ref.document.view({documentId: '_18_0_2_8630260_1446850132083_177552_51111', viewId: 'MMS_1453977130045_239e2aee-1243-4480-a6f8-61ff7bed700f', projectId: 'PROJECT-ID_10_15_15_1_41_52_PM_5b84f7be_1506a83819c__6bce_cae_tw_jpl_nasa_gov_128_149_19_85', refId: 'master', search: undefined})\">more</a></p>\n" +
    "    <div class=\"form-group\">\n" +
    "        <label>Name</label>\n" +
    "        <input class=\"form-control\" ng-model=\"tag.name\" type=\"text\"\n" +
    "            placeholder=\"Type a name for your tag here\" autofocus>\n" +
    "    </div>\n" +
    "    <div class=\"form-group\">\n" +
    "        <label>Description</label>\n" +
    "        <textarea class=\"form-control\" ng-model=\"tag.description\"></textarea>\n" +
    "    </div>\n" +
    "    <div class=\"form-group\">\n" +
    "        <label>Point in History</label>\n" +
    "        <form>\n" +
    "            <div class=\"radio radio-with-label\">\n" +
    "                <label for=\"most-recent\">\n" +
    "                    <input id=\"most-recent\" type=\"radio\" name=\"most recent\" ng-model=\"tag.lastCommit\" ng-value=\"true\">\n" +
    "                    Most Recent\n" +
    "                </label><br>\n" +
    "                <label for=\"specify-point\">\n" +
    "                    <input id=\"specify-point\" type=\"radio\" name=\"specify point\" ng-model=\"tag.lastCommit\" ng-value=\"false\">\n" +
    "                    Specify a timestamp\n" +
    "                </label>\n" +
    "                <div class=\"indent ve-secondary-text timestamp-format\">\n" +
    "                    <i>(YYYY-MM-DDTHH:MM:SS)</i>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"indent\">\n" +
    "                <input class=\"datetimepicker\" id=\"tag\">\n" +
    "            </div>\n" +
    "        </form>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-primary\" ng-click=\"ok()\">Create <i ng-show=\"oking\" class=\"fa fa-spin fa-spinner\"></i></button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "</div>");
}]);

angular.module("partials/mms/new-view.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/new-view.html",
    "<div class=\"modal-header\">\n" +
    "    <h4 class=\"item-type-View\"></i>Add view</h4>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-body\">\n" +
    "    <div class=\"ve-light-tabs modal-top-tabs\">\n" +
    "        <ul class=\"nav nav-tabs\">\n" +
    "            <li class=\"uib-tab nav-item tab-item\" ng-class=\"{'active': createForm}\">\n" +
    "                <a class=\"nav-link\" ng-click=\"createForm = true\"><i class=\"fa fa-plus\"></i>Create New</a>\n" +
    "            </li>\n" +
    "            <li class=\"uib-tab nav-item tab-item\" ng-class=\"{'active': !createForm}\">\n" +
    "                <a class=\"nav-link\" ng-click=\"createForm = false\"><i class=\"fa fa-search\"></i>Search for existing</a>\n" +
    "            </li>\n" +
    "        </ul>\n" +
    "    </div>\n" +
    "\n" +
    "    <div ng-show=\"createForm\">\n" +
    "        <div class=\"form-group\">\n" +
    "            <label>Name (optional):</label>\n" +
    "            <input class=\"form-control\" ng-model=\"newView.name\" ng-keyup=\"$event.keyCode == 13 ? ok() : null\" type=\"text\" placeholder=\"Type a name for your view here\" autofocus>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div ng-show=\"!createForm\">\n" +
    "        <div class=\"radio\">\n" +
    "            <label><input type=\"radio\" ng-model=\"newViewAggr.type\" value=\"shared\">Add view and its children</label><br>\n" +
    "            <label><input type=\"radio\" ng-model=\"newViewAggr.type\" value=\"none\">Add view only</label>\n" +
    "        </div>\n" +
    "        <mms-search mms-options=\"searchOptions\" mms-project-id=\"{{projectOb.id}}\" mms-ref-id=\"{{refOb.id}}\"></mms-search>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-primary\" ng-show=\"createForm\" ng-click=\"ok()\">Create\n" +
    "        <i ng-show=\"oking\" class=\"fa fa-spin fa-spinner\"></i>\n" +
    "    </button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "</div>");
}]);

angular.module("partials/mms/pane-center.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/pane-center.html",
    "<div ng-if=\"view !== null && search === null\">\n" +
    "    <div fa-pane pane-anchor=\"north\" pane-size=\"36px\" pane-no-toggle=\"true\" pane-no-scroll=\"true\">\n" +
    "        <div class=\"pane-center-toolbar\">\n" +
    "            <div class=\"doclib-link\">\n" +
    "                <a type=\"button\" class=\"btn btn-tools btn-sm\" target=\"_blank\" rel=\"noopener noreferrer\" ng-href=\"{{docLibLink}}\" uib-tooltip=\"Manage files\" tooltip-placement=\"bottom\" tooltip-popup-delay=\"100\">\n" +
    "                    <img src=\"assets/alfresco-icon-sm.png\">DocLib\n" +
    "                </a>\n" +
    "                <!--\n" +
    "                <button type=\"button\" class=\"btn btn-tools btn-sm share-url\" ng-if=\"!ve_fn\" ng-click=\"handleShareURL()\" uib-tooltip=\"Share Page\" tooltip-placement=\"bottom\" tooltip-popup-delay=\"100\"\n" +
    "                popover-trigger=\"outsideClick\" uib-popover-template=\"dynamicPopover.templateUrl\" popover-title=\"{{dynamicPopover.title}}\" popover-placement=\"bottom-left\">\n" +
    "                <i class=\"fa fa-share-alt\"></i></button>\n" +
    "                -->\n" +
    "            </div>\n" +
    "            <div class=\"pane-center-btn-group\">\n" +
    "                <mms-button-bar buttons=\"buttons\" mms-bb-api=\"bbApi\" class=\"bordered-button-bar\"></mms-button-bar>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div fa-pane pane-anchor=\"center\">\n" +
    "        <i class=\"pane-center-spinner fa fa-5x fa-spinner fa-spin\" ng-show=\"ve_viewContentLoading\"></i>\n" +
    "        <div ng-hide=\"ve_viewContentLoading\" class=\"container-fluid\">\n" +
    "            <div class=\"pane-center-inner\">\n" +
    "                <div class=\"ve-notify-banner\" ng-show=\"refOb.type === 'Tag'\">\n" +
    "                    <span><strong>Tags are read only:</strong> Switch to a branch to edit</span>\n" +
    "                </div>\n" +
    "                <div class=\"ve-secondary-text\">{{comments.count}} Comment<span ng-if=\"comments.count !== 1\">s</span>\n" +
    "                    <span ng-if=\"comments.count > 0\">\n" +
    "                        , Last Commented {{comments.lastCommented | date:'M/d/yy h:mm a'}} by <b>{{comments.lastCommentedBy}}</b></span>\n" +
    "                </div>\n" +
    "                <div id=\"print-div\" ng-if=\"viewOb\">\n" +
    "                    <mms-view mms-element-id=\"{{viewOb.id}}\" mms-commit-id=\"latest\"\n" +
    "                              mms-project-id=\"{{projectOb.id}}\" mms-ref-id=\"{{refOb.id}}\"\n" +
    "                              mms-view-api=\"viewApi\" mms-link=\"vidLink\"></mms-view>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div ng-if=\"search !== null\">\n" +
    "    <i class=\"pane-center-spinner fa fa-5x fa-spinner fa-spin\" ng-show=\"ve_viewContentLoading\"></i>\n" +
    "    <mms-search mms-options=\"searchOptions\" mms-project-id=\"{{projectOb.id}}\" mms-ref-id=\"{{refOb.id}}\"></mms-search>\n" +
    "</div>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"shareUrlTemplate.html\">\n" +
    "    <p id=\"ve-short-url\">{{(shortUrl)}}</p>\n" +
    "    <button ng-click=\"copyToClipboard($event)\" class=\"btn btn-sm btn-default\"><i class=\"fa fa-copy\"></i>Copy</button>\n" +
    "</script>\n" +
    "");
}]);

angular.module("partials/mms/pane-left.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/pane-left.html",
    "<div fa-pane pane-anchor=\"north\" pane-size=\"78px\" pane-no-toggle=\"true\" pane-no-scroll=\"true\">\n" +
    "    <div class=\"pane-left\">\n" +
    "        <div class=\"pane-left-toolbar\" role=\"toolbar\">\n" +
    "            <mms-button-bar buttons=\"buttons\" mms-bb-api=\"bbApi\"></mms-button-bar>\n" +
    "        </div>\n" +
    "        <div class=\"tree-options\">\n" +
    "            <mms-button-bar buttons=\"treeButtons\" mms-bb-api=\"tbApi\"></mms-button-bar>\n" +
    "            <input class=\"ve-plain-input\" ng-model-optoins=\"{debounce: 1000}\"\n" +
    "                ng-model=\"treeOptions.search\" type=\"text\" placeholder=\"{{filterInputPlaceholder}}\"\n" +
    "                ng-change=\"searchInputChangeHandler();\" style=\"flex:2\">\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "<div fa-pane pane-anchor=\"center\" pane-no-toggle=\"true\">\n" +
    "    <div class=\"pane-left\" style=\"display:table;\">\n" +
    "        <mms-tree ng-show=\"'showTree'==(activeMenu)\" tree-data=\"treeData\"\n" +
    "                tree-control=\"treeApi\" initial-selection=\"{{ve_treeInitial}}\" options=\"treeOptions\"></mms-tree>\n" +
    "\n" +
    "        <div data-ng-repeat=\"view in ::treeViewModes\" ng-show=\"view.id==(activeMenu)\">\n" +
    "            <h4 style=\"margin: 3px 0px 3px 10px;\">List of {{view.title}}</h4>\n" +
    "            <ul class=\"nav nav-list nav-pills nav-stacked abn-tree\">\n" +
    "                <li ng-repeat=\"row in view.branchList | filter:treeOptions.search track by $index\"\n" +
    "                    class=\"abn-tree-row\">\n" +
    "                    <div class=\"arrow\" ng-click=\"user_clicks_branch(row)\"\n" +
    "                        ng-class=\"{'active-text': row.branch.selected}\" id=\"tree-branch-{{view.id}}-{{row.data.id}}\">\n" +
    "                        <a class=\"tree-item\" style=\"padding-left: 20px; position:relative;\">\n" +
    "                            <i ng-class=\"{'active-text': row.branch.selected}\"\n" +
    "                            class=\"indented tree-icon fa {{view.icon}}\"></i>\n" +
    "                            <!-- TODO active branch -->\n" +
    "                            <span class=\"indented tree-label\" ng-class=\"{'active-text': row.branch.selected}\">{{row.data._veNumber}} {{row.data.name}}</span>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </li>\n" +
    "            </ul>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("partials/mms/pane-right.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/pane-right.html",
    "<div class=\"pane-right\">\n" +
    "    <div ng-if=\"documentOb\">\n" +
    "        <div class=\"container-fluid\" ng-show=\"show.element\">\n" +
    "            <h4 ng-if=\"specApi.getEditing()\" class=\"right-pane-title\">Edit Element</h4>\n" +
    "            <h4 ng-if=\"!specApi.getEditing()\" class=\"right-pane-title\">Preview Element</h4>\n" +
    "            <form class=\"form-horizontal\">\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label class=\"col-sm-3 control-label\">Edits ({{veEditsLength()}}):</label>\n" +
    "                    <div class=\"col-sm-9\">\n" +
    "                        <select class=\"form-control\"\n" +
    "                            ng-options=\"eid as edit.type + ': ' + edit.name for (eid, edit) in ve_edits\"\n" +
    "                            ng-model=\"tracker.etrackerSelected\" ng-change=\"etrackerChange()\">\n" +
    "                        </select>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </form>\n" +
    "            <hr class=\"spec-title-divider\">\n" +
    "            <mms-spec mms-element-id=\"{{specInfo.id}}\" mms-commit-id=\"{{specInfo.commitId}}\"\n" +
    "                mms-ref-id=\"{{specInfo.refId}}\" mms-project-id=\"{{specInfo.projectId}}\"\n" +
    "                mms-display-old-content=\"specInfo.mmsDisplayOldContent\" mms-spec-api=\"specApi\"></mms-spec>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div ng-if=\"documentOb\">\n" +
    "        <div class=\"container-fluid\" ng-if=\"show.history\">\n" +
    "            <mms-history mms-element-id=\"{{specInfo.id}}\" mms-ref-id=\"{{specInfo.refId}}\"\n" +
    "                mms-project-id=\"{{specInfo.projectId}}\"></mms-history>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"container-fluid container-tags\" id=\"ve-branches-tags\" ng-show=\"show.tags\">\n" +
    "        <h4 class=\"right-pane-title\">Project Branches/Tags</h4>\n" +
    "        <hr class=\"spec-title-divider\">\n" +
    "        <mms-ref-list mms-doc-id=\"{{documentOb.id}}\" mms-project-id=\"{{projectOb.id}}\"\n" +
    "        mms-ref-id=\"{{refOb.id}}\" mms-ref-type=\"{{refOb.type}}\" mms-branches=\"branchObs\" mms-tags=\"tagObs\"></mms-ref-list>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"container-fluid\" ng-show=\"show.reorder\">\n" +
    "        <mms-view-reorder mms-element-id=\"{{viewOb.id}}\" mms-project-id=\"{{viewOb._projectId}}\"\n" +
    "            mms-commit-id=\"{{viewCommitId}}\" mms-order=\"true\" mms-ref-id=\"{{viewOb._refId}}\"\n" +
    "            mms-view-reorder-api=\"viewContentsOrderApi\"></mms-view-reorder>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"container-fluid\" ng-if=\"show.jobs\">\n" +
    "        <mms-jobs mms-doc-id=\"{{documentOb.id}}\" mms-project-id=\"{{projectOb.id}}\"\n" +
    "            mms-ref-id=\"{{refOb.id}}\"></mms-jobs>\n" +
    "    </div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("partials/mms/printConfirm.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/printConfirm.html",
    "<div class=\"modal-header\">\n" +
    "    <h4>{{action}} {{type | lowercase}}</h4>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-body\">\n" +
    "    <p ng-if=\"hasError\" class=\"mms-error-icon\">\n" +
    "        WARNING: There are cross reference errors in this document that will be reflected in the export.\n" +
    "    </p>\n" +
    "    <p ng-if=\"unsaved\" class=\"mms-error-icon\">\n" +
    "        WARNING: Unsaved changes may not be reflected (alt + a to save all)\n" +
    "    </p>\n" +
    "    <p ng-if=\"mode === 1\">\n" +
    "      This will bring up a popup where you can {{action}} the contents of this {{type === 'DOCUMENT' ? 'document' : 'view'}} , please disable any popup blockers if you don't\n" +
    "      see the popup. \n" +
    "      <br/><br/>If you don't see certain content appearing in the popup, please wait until the full document appears in this pane before clicking {{action}}.\n" +
    "    </p>\n" +
    "    <p ng-if=\"mode !== 1\">\n" +
    "     <span ng-if=\"type === 'VIEW'\">\n" +
    "      Click on {{action | uppercase}} to generate a {{label}} of this view (it will not include table of contents or cover pages). You will receive an email with subject line \"HTML to {{label}} generation completed\" with a link to the generated {{label | uppercase}}. If you want the full document instead, please click on GO TO FULL DOCUMENT.\n" +
    "     </span>\n" +
    "     <span ng-if=\"type === 'DOCUMENT'\">\n" +
    "      Please wait until the full document appears in this pane before continuing. You will receive an email with subject line \"HTML to {{label}} generation completed\" with a link to the generated {{label}}.\n" +
    "     </span>\n" +
    "    </p>\n" +
    "    <p ng-if=\"type == 'DOCUMENT' && mode === 3\">\n" +
    "      Choose either basic or advanced styling options.\n" +
    "    </p>    \n" +
    "    <br/>\n" +
    "    <div ng-if=\"type == 'DOCUMENT' && mode !== 1\">\n" +
    "    </div>\n" +
    "    <div ng-if=\"type == 'DOCUMENT' && mode !== 1\"><input type=\"checkbox\" ng-model=\"model.genTotf\"> Include List of Tables, Figures and Equations</div>\n" +
    "    <!-- <div ng-if=\"model.genTotf\" class=\"indent\"><input type=\"checkbox\" ng-model=\"model.htmlTotf\"> Use HTML for List (will not include equations and may differ from web numbering)</div> -->\n" +
    "    <br>\n" +
    "\n" +
    "    <div ng-if=\"type == 'DOCUMENT' && mode === 3\">\n" +
    "        <br>\n" +
    "        <form class=\"ve-light-tabs\">\n" +
    "            <input type=\"radio\" name=\"Options\" ng-click=\"customizeDoc.useCustomStyle = false\" data-ng-model=\"customizeDoc.useCustomStyle\" data-ng-value=\"false\">\n" +
    "            <label for=\"Basic\">Basic Options</label>&nbsp;&nbsp;&nbsp;\n" +
    "            <input type=\"radio\" name=\"Options\" ng-click=\"customizeDoc.useCustomStyle = true\" data-ng-model=\"customizeDoc.useCustomStyle\" data-ng-value=\"true\">\n" +
    "            <label for=\"Advanced\">Advanced Options (CSS)</label>\n" +
    "        </form>\n" +
    "\n" +
    "        <div class=\"well\" ng-show=\"!customizeDoc.useCustomStyle\">\n" +
    "        <form role=\"form\">\n" +
    "\n" +
    "            <div ng-if=\"mode !== 1\">\n" +
    "                <label>Orientation</label><br>\n" +
    "                <form>\n" +
    "                    <input type=\"radio\" name=\"export-orientation\" ng-model=\"model.landscape\" ng-value=\"false\">\n" +
    "                    <label for=\"portrait\" class=\"ve-font-weight-normal\">&nbsp;<i class=\"fa fa-file ve-secondary-text\"></i> Portrait</label><br>\n" +
    "                    <input type=\"radio\" name=\"export-orientation\" ng-model=\"model.landscape\" ng-value=\"true\">\n" +
    "                    <label for=\"landscape\" class=\"ve-font-weight-normal\">&nbsp;<i class=\"fa fa-file fa-rotate-270 ve-secondary-text\"></i> Landscape\n" +
    "                </form>\n" +
    "            </div>\n" +
    "            <br>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label>Header</label>\n" +
    "                <div class=\"row narrow-gutter-row\">\n" +
    "                    <div class=\"col-md-3\">\n" +
    "                        <input class=\"ve-plain-input\" type=\"text\" ng-model=\"meta['top-left']\" placeholder=\"Left\" aria-labelledby=\"left header\">\n" +
    "                    </div>\n" +
    "                    <div class=\"col-md-6\">\n" +
    "                        <input class=\"ve-plain-input\" type=\"text\" ng-model=\"meta.top\" placeholder=\"Center\" aria-labelledby=\"center header\">\n" +
    "                    </div>\n" +
    "                    <div class=\"col-md-3\">\n" +
    "                        <input class=\"ve-plain-input\" type=\"text\" ng-model=\"meta['top-right']\" placeholder=\"Right\" aria-labelledby=\"right header\">\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label for=\"footer\">Footer</label>\n" +
    "                <div class=\"row narrow-gutter-row\">\n" +
    "                    <div class=\"col-md-3\">\n" +
    "                        <input class=\"ve-plain-input\" type=\"text\" ng-model=\"meta['bottom-left']\" placeholder=\"Left\" aria-labelledby=\"left footer\">\n" +
    "                    </div>\n" +
    "                    <div class=\"col-md-6\">\n" +
    "                        <input class=\"ve-plain-input\" type=\"text\" ng-model=\"meta.bottom\" placeholder=\"Center\" aria-labelledby=\"center footer\">\n" +
    "                    </div>\n" +
    "                    <div class=\"col-md-3\">\n" +
    "                        <input class=\"ve-plain-input\" type=\"text\" ng-model=\"meta['bottom-right']\"  placeholder=\"Right\" aria-labelledby=\"right footer\">\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <p class=\"help-block\"><i>Use 'counter(page)' for page number</i></p>\n" +
    "\n" +
    "        </form>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"well\" ng-show=\"customizeDoc.useCustomStyle\">\n" +
    "            <form ng-submit=\"saveStyleUpdate()\" role=\"form\">\n" +
    "\n" +
    "                <div class=\"form-group\">\n" +
    "                    <textarea class=\"form-control\" rows=\"6\" style=\"width:100%\" ng-model=\"customizeDoc.customCSS\"></textarea>\n" +
    "                </div>\n" +
    "                <p class=\"help-block\">\n" +
    "                    <a target=\"_blank\" href=\"https://wiki.jpl.nasa.gov/display/opencae/View+Editor+User+Guide%3A+8+PDF+Customization\">Example CSS customizations</a>\n" +
    "                </p>\n" +
    "                <button class=\"btn btn-default\" type=\"submit\"><i class=\"fa fa-save\"></i>Save CSS for document<span ng-if=\"elementSaving\"><i class=\"fa fa-spinner fa-spin\"></i></span></button>\n" +
    "                <button class=\"btn btn-default\" type=\"button\" ng-click=\"preview()\">Preview</button>\n" +
    "            </form>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button ng-if=\"type == 'VIEW' && docOption\" class=\"btn btn-primary\" ng-click=\"fulldoc()\">Go to full document</button>\n" +
    "    <button class=\"btn btn-primary\" ng-click=\"print()\">{{action | uppercase}}</button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "</div>\n" +
    "");
}]);

angular.module("partials/mms/redirect.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/redirect.html",
    "<div id=\"ve-origin-select\" class=\"row\">\n" +
    "    <div class=\"account-wall-lg\" ng-if=\"redirect_from_old_site\">\n" +
    "            <div ng-if=\"!redirect_element && !redirect_noResults && !redirect_relatedDocs.length\">\n" +
    "                <h2>This link is not valid</h2>\n" +
    "                <h4><i class=\"fa fa-spinner fa-spin\" aria-hidden=\"true\"></i> We are searching for your content</h4>\n" +
    "            </div>\n" +
    "            <!-- Content moved and results found -->\n" +
    "            <div ng-if=\"!redirect_noResults && redirect_element.link\">\n" +
    "                <h2>This content has moved</h2>\n" +
    "                <h4>You will be redirected to the new location at \n" +
    "                    <a style=\"text-decoration:underline;\" ng-if=\"redirect_element.link\" ui-sref=\"{{ redirect_element.link }}\">\n" +
    "                    <!-- <i ng-class=\"{'fa fa-folder': redirect_element.type === 'group', 'fa fa-file': $last && redirect_element.type === 'doc'}\"></i> -->\n" +
    "                    {{ redirect_element.name }}</a> in 10 seconds.\n" +
    "                </h4>\n" +
    "                <!-- <button class=\"btn btn-primary\"><i class=\"fa fa-bookmark\" aria-hidden=\"true\" style=\"padding-right:10px;\"></i>Bookmark New Link</button> -->\n" +
    "                <button style=\"margin-top:60px;\" class=\"btn btn-primary\" ng-click=\"resetSelectPage()\">View Editor Home</button>\n" +
    "            </div>\n" +
    "            <!-- no content found -->\n" +
    "            <div ng-if=\"redirect_noResults\">\n" +
    "                <h2>This link is not valid</h2>\n" +
    "                <h4>Related content not found for this link. If you believe your content was not moved or deleted, please navigate to it in View Editor and rebookmark.</h4>\n" +
    "                <button style=\"margin-top:60px;\" class=\"btn btn-primary\" ng-click=\"resetSelectPage()\">View Editor Home</button>\n" +
    "            </div>\n" +
    "            <!-- views found in docs - allow user to select -->\n" +
    "            <div ng-if=\"redirect_relatedDocs.length\">\n" +
    "                <h2>This link is not valid</h2>\n" +
    "                <h4>Here are documents related to the content you are looking for:</h4>\n" +
    "                <div ng-repeat=\"relatedDocument in redirect_relatedDocs\" class=\"elem-documentation\">\n" +
    "                    <a style=\"text-decoration:underline;\" ng-repeat=\"relatedView in relatedDocument._parentViews\" ui-sref=\"project.ref.document.view({documentId: relatedDocument.id, viewId: relatedView.id, projectId: relatedDocument._projectId, refId: relatedDocument._refId, search: undefined})\" ng-click=\"userRelatedClick($event, relatedDocument, relatedView, elem)\">\n" +
    "                        <i class=\"fa fa-file\" aria-hidden=\"true\"></i>\n" +
    "                        <mms-view-link mms-project-id=\"{{elem._projectId}}\" mms-ref-id=\"{{elem._refId}}\" mms-doc-id=\"{{relatedDocument.id}}\" mms-element-id=\"{{relatedDocument.id}}\"></mms-view-link> > <mms-view-link mms-project-id=\"{{elem._projectId}}\" mms-ref-id=\"{{elem._refId}}\" mms-doc-id=\"{{relatedDocument.id}}\" mms-element-id=\"{{relatedView.id}}\"></mms-view-link><br/>\n" +
    "                    </a>\n" +
    "                </div>\n" +
    "                <button style=\"margin-top:60px;\" class=\"btn btn-primary\" ng-click=\"resetSelectPage()\">View Editor Home</button>\n" +
    "                <!-- <h4 style=\"margin-top:60px;\"><a style=\"text-decoration:underline;\" ng-click=\"resetSelectPage()\">View Editor home</a></h4> -->\n" +
    "            </div>\n" +
    "    </div>\n" +
    "</div>");
}]);

angular.module("partials/mms/reorder-groups.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/reorder-groups.html",
    "<!-- Nested node template -->\n" +
    "<div class=\"group-reorder-container\">\n" +
    "    <script type=\"text/ng-template\" id=\"nodes_renderer_group.html\">\n" +
    "        <div ng-class=\"targetId === node.id ? 'highlighted' : ''\" ui-tree-handle>\n" +
    "            <i ng-class=\"node.type === 'view' ? 'fa fa-file' : node.type === 'group' ? 'fa fa-folder' : 'fa fa-home'\"></i> {{node.name}}\n" +
    "        </div>\n" +
    "        <ol ui-tree-nodes=\"\" ng-model=\"node.children\">\n" +
    "            <li ng-repeat=\"node in node.children\" ui-tree-node ng-include=\"'nodes_renderer_group.html'\">\n" +
    "            </li>\n" +
    "        </ol>\n" +
    "    </script>\n" +
    "\n" +
    "    <div class=\"container-tree-reorder container-fluid\">\n" +
    "        <button class=\"btn-tree-reorder-save btn btn-primary\" ng-click=\"saveReorder()\">Save <i ng-If=\"isSaving\" class='fa fa-spin fa-spinner'></i></button>\n" +
    "        <button class=\"btn-tree-reorder-save btn btn-default\" ng-click=\"cancelReorder()\">Cancel</button>\n" +
    "        <br>\n" +
    "        <p>Move group/document to/from a group. Only Grouping is preserved. Ordering is not.</p>\n" +
    "        <div class=\"well\" ui-tree=\"treeOptions\">\n" +
    "            <ol ui-tree-nodes=\"\" class=\"root\" ng-model=\"tree\">\n" +
    "                <li ng-repeat=\"node in tree\" ui-tree-node ng-include=\"'nodes_renderer_group.html'\"></li>\n" +
    "            </ol>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("partials/mms/reorder-views.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/reorder-views.html",
    "<!-- Nested node template -->\n" +
    "<script type=\"text/ng-template\" id=\"nodes_renderer.html\">\n" +
    "    <div ui-tree-handle>\n" +
    "        {{node.old}} &rarr; {{node.new}} {{node.name}} ({{node.aggr}})\n" +
    "    </div>\n" +
    "    <ol ui-tree-nodes=\"\" ng-model=\"node.children\">\n" +
    "        <li ng-repeat=\"node in node.children\" ui-tree-node ng-include=\"'nodes_renderer.html'\">\n" +
    "        </li>\n" +
    "    </ol>\n" +
    "</script>\n" +
    "\n" +
    "<div class=\"container-tree-reorder container-fluid\">\n" +
    "    <button class=\"btn-tree-reorder-save btn btn-primary\" ng-click=\"save()\">Save <i class=\"{{saveClass}}\"></i></button>\n" +
    "    <button class=\"btn-tree-reorder-save btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "    <br>\n" +
    "    <!--<p>Note: you cannot reorder views under a view that's labeled (none) - these views may have children but are not shown and is not part of this document</p>-->\n" +
    "    <p>To reorder text, tables, images, equations, and sections, use the <i class=\"fa fa-arrows-v\"></i> in the right pane.</p>\n" +
    "    <div class=\"well\" ui-tree=\"treeOptions\">\n" +
    "        <ol ui-tree-nodes=\"\" class=\"root\" ng-model=\"tree\">\n" +
    "            <li ng-repeat=\"node in tree\" ui-tree-node ng-include=\"'nodes_renderer.html'\"></li>\n" +
    "        </ol>\n" +
    "    </div>\n" +
    "</div>");
}]);

angular.module("partials/mms/select.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/select.html",
    "<div id=\"ve-origin-select\" class=\"row\">\n" +
    "    <div class=\"account-wall-lg\" ng-if=\"redirect_from_old_site\">\n" +
    "        <h2>This is not a valid link</h2>\n" +
    "        <div class=\"flex\">\n" +
    "            <div class=\"two-thirds-flex\">\n" +
    "                <h4>Please navigate to your document by selecting the corresponding organization and project to continue.</h4>\n" +
    "            </div>\n" +
    "            <div class=\"one-third-flex\">\n" +
    "                <form name=\"loginForm\" ng-submit=\"continue()\">\n" +
    "                    <div class=\"btn-group ve-dark-dropdown-wide\" uib-dropdown>\n" +
    "                        <span class=\"label-dropdown\">Org:</span>\n" +
    "                        <button type=\"button\" class=\"dropdown-toggle\" uib-dropdown-toggle>\n" +
    "                            <span>{{ selectedOrg }}<i class=\"fa fa-caret-down\" aria-hidden=\"true\"></i></span>\n" +
    "                        </button>\n" +
    "                        <ul class=\"dropdown-menu list-with-selected-item\" uib-dropdown-menu role=\"menu\">\n" +
    "                            <li ng-model=\"selectedOrg\" ng-repeat=\"org in orgs | orderBy: 'name'\" ng-click=\"selectOrg(org)\"\n" +
    "                            ng-class=\"{'checked-list-item': org.name === selectedOrg}\">{{ org.name }}</li>\n" +
    "                        </ul>\n" +
    "                    </div>\n" +
    "                    <div class=\"btn-group ve-dark-dropdown-wide\" uib-dropdown>\n" +
    "                        <span class=\"label-dropdown\">Project:</span>\n" +
    "                        <button type=\"button\" class=\"dropdown-toggle\" uib-dropdown-toggle ng-disabled=\"!selectedOrg || !projects.length\">\n" +
    "                            <span ng-hide=\"projects.length || !selectedOrg\">No Projects for selected Org</span>\n" +
    "                            <span ng-show=\"projects.length\">{{ selectedProject }}<i class=\"fa fa-caret-down\" aria-hidden=\"true\"></i></span>\n" +
    "                        </button>\n" +
    "                        <ul class=\"dropdown-menu list-with-selected-item\" uib-dropdown-menu role=\"menu\">\n" +
    "                            <li ng-model=\"selectedProject\" ng-repeat=\"project in projects | orderBy: 'name'\"\n" +
    "                                ng-class=\"{'checked-list-item': project.name === selectedProject}\"\n" +
    "                                ng-click=\"selectProject(project)\">{{ project.name }}\n" +
    "                            </li>\n" +
    "                        </ul>\n" +
    "                    </div>\n" +
    "                    <button class=\"btn btn-block btn-primary\" type=\"submit\"  ng-disabled=\"!selectedProject || !selectedOrg\">Continue\n" +
    "                        <span ng-if=\"spin\"><i class=\"fa fa-spin fa-spinner\"></i></span>\n" +
    "                    </button>\n" +
    "                </form>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"account-wall\" ng-if=\"!redirect_from_old_site\">\n" +
    "        <div ng-class=\"{'fade-in': fromLogin}\">\n" +
    "            <a class=\"select-logout-button\" ng-click=\"logout()\"><i class=\"fa fa-arrow-left back-to-account\" aria-hidden=\"true\"></i></a>\n" +
    "            <h2>{{ pageTitle }}</h2>\n" +
    "        </div>\n" +
    "        <div ng-class=\"{'animated-fade-in-slide': fromLogin}\">\n" +
    "            <form name=\"loginForm\" ng-submit=\"continue()\">\n" +
    "                <div class=\"btn-group ve-dark-dropdown-wide\" uib-dropdown>\n" +
    "                    <span class=\"label-dropdown\">Org:</span>\n" +
    "                    <button type=\"button\" class=\"dropdown-toggle\" uib-dropdown-toggle>\n" +
    "                        <span>{{ selectedOrg }}<i class=\"fa fa-caret-down\" aria-hidden=\"true\"></i></span>\n" +
    "                    </button>\n" +
    "                    <ul class=\"dropdown-menu list-with-selected-item\" uib-dropdown-menu role=\"menu\">\n" +
    "                        <li ng-model=\"selectedOrg\" ng-repeat=\"org in orgs | orderBy: 'name'\" ng-click=\"selectOrg(org)\"\n" +
    "                        ng-class=\"{'checked-list-item': org.name === selectedOrg}\">{{ org.name }}</li>\n" +
    "                    </ul>\n" +
    "                </div>\n" +
    "                <div class=\"btn-group ve-dark-dropdown-wide\" uib-dropdown>\n" +
    "                    <span class=\"label-dropdown\">Project:</span>\n" +
    "                    <button type=\"button\" class=\"dropdown-toggle\" uib-dropdown-toggle ng-disabled=\"!selectedOrg || !projects.length\">\n" +
    "                        <span ng-hide=\"projects.length || !selectedOrg\">No Projects for selected Org</span>\n" +
    "                        <span ng-show=\"projects.length\">{{ selectedProject }}<i class=\"fa fa-caret-down\" aria-hidden=\"true\"></i></span>\n" +
    "                    </button>\n" +
    "                    <ul class=\"dropdown-menu list-with-selected-item\" uib-dropdown-menu role=\"menu\">\n" +
    "                        <li ng-model=\"selectedProject\" ng-repeat=\"project in projects | orderBy: 'name'\"\n" +
    "                            ng-class=\"{'checked-list-item': project.name === selectedProject}\"\n" +
    "                            ng-click=\"selectProject(project)\">{{ project.name }}\n" +
    "                        </li>\n" +
    "                    </ul>\n" +
    "                </div>\n" +
    "                <button class=\"btn btn-block btn-primary\" type=\"submit\" ng-disabled=\"!selectedProject || !selectedOrg\">Continue\n" +
    "                    <span ng-if=\"spin\"><i class=\"fa fa-spin fa-spinner\"></i></span>\n" +
    "                </button>\n" +
    "            </form>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>");
}]);

angular.module("partials/mms/selectModal.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/selectModal.html",
    "<div id=\"modal-window\" class=\"ve-dark-modal\">\n" +
    "    <div class=\"modal-header\">\n" +
    "        <h4 class=\"modal-title\">Switch Org</h4>\n" +
    "    </div>\n" +
    "    <div class=\"modal-body\" id=\"modal-body\">\n" +
    "        <div class=\"btn-group ve-dark-dropdown-wide\" uib-dropdown>\n" +
    "            <span class=\"label-dropdown\">Org:</span>\n" +
    "            <button id=\"project-btn-keyboard-nav\" type=\"button\" class=\"dropdown-toggle\" uib-dropdown-toggle>\n" +
    "                <span>{{ selectedOrg }}<i class=\"fa fa-caret-down\" aria-hidden=\"true\"></i></span>\n" +
    "            </button>\n" +
    "            <ul class=\"dropdown-menu list-with-selected-item\" uib-dropdown-menu role=\"menu\"\n" +
    "                aria-labelledby=\"project-btn-keyboard-nav\">\n" +
    "                <li ng-repeat=\"org in orgs | orderBy: 'name'\" ng-click=\"selectOrg(org)\"\n" +
    "                    ng-class=\"{'checked-list-item': org.name === selectedOrg}\">{{ org.name }}\n" +
    "                </li>\n" +
    "            </ul>\n" +
    "        </div>\n" +
    "        <div class=\"btn-group ve-dark-dropdown-wide\" uib-dropdown keyboard-nav>\n" +
    "            <span class=\"label-dropdown\">Project:</span>\n" +
    "            <button id=\"module-btn-keyboard-nav\" type=\"button\" class=\"dropdown-toggle\" uib-dropdown-toggle\n" +
    "                    ng-disabled=\"!selectedOrg || !projects.length\">\n" +
    "                <span ng-hide=\"projects.length\">No Projects for selected Org</span>\n" +
    "                <span ng-show=\"projects.length\">{{ selectedProject }}<i class=\"fa fa-caret-down\" aria-hidden=\"true\"></i></span>\n" +
    "            </button>\n" +
    "            <ul class=\"dropdown-menu list-with-selected-item\" uib-dropdown-menu role=\"menu\"\n" +
    "                aria-labelledby=\"module-btn-keyboard-nav\">\n" +
    "                <li ng-repeat=\"project in projects | orderBy: 'name'\" ng-click=\"selectProject(project)\"\n" +
    "                    ng-class=\"{'checked-list-item': project.name === selectedProject}\">{{ project.name }}\n" +
    "                </li>\n" +
    "            </ul>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"modal-footer ng-scope\">\n" +
    "        <button class=\"btn btn-primary\" type=\"button\" ng-click=\"continue()\" ng-disabled=\"!selectedProject || !selectedOrg\">\n" +
    "            Continue<span ng-if=\"spin\"><i class=\"fa fa-spin fa-spinner\"></i></span>\n" +
    "        </button>\n" +
    "        <button class=\"btn btn-default\" type=\"button\" ng-click=\"cancel()\">Cancel</button>\n" +
    "    </div>\n" +
    "</div>");
}]);

angular.module("partials/mms/tableExport.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/tableExport.html",
    "<div class=\"modal-header\">\n" +
    "    <h4>Export table(s) to CSV in {{type | lowercase}}</h4>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-body\">\n" +
    "    <p ng-if=\"type == 'DOCUMENT'\">\n" +
    "      This will bring up a popup where you can copy/paste the table contents of this document as CSV, please disable any popup blockers if you don't \n" +
    "      see the popup. \n" +
    "      <br/><br/>If you don't see certain content appearing in the popup, please wait until the full document appears in this pane before clicking export.\n" +
    "    </p>\n" +
    "    <p ng-if=\"type == 'VIEW'\">\n" +
    "        This will bring up a popup where you can copy/paste the table contents of this view as CSV, please disable any popup blockers if you don't \n" +
    "      see the popup. \n" +
    "      <br/><br/>\n" +
    "      If you don't see certain content appearing in the popup, please wait until the full document appears in this pane before clicking export.\n" +
    "      <!--<br/><br/>If you would like all the tables in the entire document instead, please click on 'GO TO FULL DOCUMENT' and click export again when it finishes loading. -->\n" +
    "    </p>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-primary\" ng-click=\"export()\">Export to CSV</button>\n" +
    "    <!--<button ng-if=\"type == 'VIEW'\" class=\"btn btn-sm btn-primary\" ng-click=\"fulldoc()\">GO TO FULL DOCUMENT</button>-->\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "</div>\n" +
    "");
}]);

angular.module("partials/mms/veFooter.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/veFooter.html",
    "<div class=\"footer\" ng-transclude></div>");
}]);

angular.module("partials/mms/veMenu.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/veMenu.html",
    "<nav class=\"project-level-header navbar navbar-inverse navbar-fixed-top block\" role=\"navigation\">\n" +
    "    <div class=\"btn-group ve-dark-dropdown-nav pull-left\" uib-dropdown keyboard-nav>\n" +
    "        <button type=\"button\" class=\"dropdown-toggle\" ng-click=\"updateProjectChecked()\" uib-dropdown-toggle>\n" +
    "            <span class=\"label-dropdown\">Project:&nbsp;</span><span class=\"selected-dropdown\">{{ currentProject }}</span>\n" +
    "            <span><i class=\"fa fa-caret-down\" aria-hidden=\"true\"></i></span>\n" +
    "        </button>\n" +
    "        <ul class=\"dropdown-menu list-with-selected-item\" uib-dropdown-menu role=\"menu\">\n" +
    "            <li ng-repeat=\"project in projects | orderBy: 'name'\" ng-click=\"updateProject(project)\"\n" +
    "                ng-class=\"{'checked-list-item': project.name === currentProject}\">\n" +
    "                <a ng-href=\"{{getHrefForProject(project);}}\"> {{ project.name }} </a>\n" +
    "            </li>\n" +
    "        </ul>\n" +
    "    </div>\n" +
    "    <div class=\"breadcrumbs\">\n" +
    "        <ul>\n" +
    "            <li ng-style=\"truncateStyle\">\n" +
    "                <a class=\"back-to-proj\" ui-sref=\"project.ref({refId: branch.id? branch.id : 'master', search: undefined})\" ui-sref-opts=\"{reload:true}\"\n" +
    "                    uib-tooltip=\"{{ currentProject }}\" tooltip-trigger=\"mouseenter\" tooltip-popup-delay=\"100\" tooltip-placement=\"bottom\">\n" +
    "                    <i class=\"fa fa-home\" aria-hidden=\"true\"></i>\n" +
    "                </a>\n" +
    "            </li>\n" +
    "            <li ng-style=\"truncateStyle\" ng-show=\"!isRefsView()\" ng-repeat=\"breadcrumb in breadcrumbs track by $index\">\n" +
    "                <span> &#8250;</span>\n" +
    "                <a ui-sref=\"{{ breadcrumb.link }}\" uib-tooltip=\"{{ breadcrumb.name }}\" tooltip-trigger=\"mouseenter\" tooltip-popup-delay=\"100\" tooltip-placement=\"bottom\">\n" +
    "                    <i ng-class=\"{'fa fa-file': $last && breadcrumb.type === 'doc'}\" aria-hidden=\"true\"></i>{{ breadcrumb.name }}\n" +
    "                </a>\n" +
    "            </li>\n" +
    "        </ul>\n" +
    "    </div>\n" +
    "\n" +
    "    <div ng-show=\"!isRefsView()\" class=\"nav navbar-nav navbar-right\" style=\"padding-right: 15px\">\n" +
    "        <div class=\"btn-group ve-dark-dropdown-nav\" uib-dropdown keyboard-nav auto-close=\"outsideClick\">\n" +
    "            <button id=\"task-selection-button\" type=\"button\" class=\"dropdown-toggle\" uib-dropdown-toggle>\n" +
    "                <span class=\"label-dropdown\">{{ currentRef.type }}:</span>\n" +
    "                <span class=\"selected-dropdown\">{{ currentRef.name }}</span>\n" +
    "                <i class=\"fa fa-caret-down\" aria-hidden=\"true\"></i>\n" +
    "            </button>\n" +
    "            <ul class=\"dropdown-menu pull-right list-with-selected-item list-with-button list-with-tabs\" uib-dropdown-menu role=\"menu\"\n" +
    "                aria-labelledby=\"task-selection-button\">\n" +
    "                <li class=\"button-item\">\n" +
    "                    <button class=\"btn btn-large btn-block btn-primary\" ng-click=\"refsView()\">Manage Branches/Tags\n" +
    "                    </button>\n" +
    "                </li>\n" +
    "                <li class=\"button-item\">\n" +
    "                    <form class=\"ve-dark-minor-search\">\n" +
    "                        <input placeholder=\"Filter branches/tags\" ng-model=\"refFilter\">\n" +
    "                    </form>\n" +
    "                </li>\n" +
    "                <uib-tabset active=\"active\" type=\"tabs\" justified=\"false\">\n" +
    "                    <uib-tab index=\"0\" classes=\"tab-item\" heading=\"Branches\">\n" +
    "                        <li ng-repeat=\"branch in branches | orderBy:'name' | filter:{name:refFilter} \" ng-click=\"updateBranch(branch)\"\n" +
    "                            ng-class=\"{'checked-list-item': branch.name === currentBranch, 'branch-disabled': branch.status == 'creating'}\"\n" +
    "                            is-open=\"false\" tooltip-placement=\"left\" uib-tooltip-html=\"htmlTooltip\"\n" +
    "                            tooltip-append-to-body=\"branch.status == 'creating'\" tooltip-enable=\"branch.status == 'creating'\">\n" +
    "                            <a ng-href=\"{{getHrefForBranch(branch);}}\" ng-style=\"{display: 'block'}\"> {{ branch.name }} </a>\n" +
    "                        </li>\n" +
    "                    </uib-tab>\n" +
    "                    <uib-tab index=\"1\" classes=\"tab-item\" heading=\"Tags\">\n" +
    "                        <li ng-if=\"tags.length\" ng-repeat=\"tag in tags | orderBy:'name' | filter:{name:refFilter}\" ng-click=\"updateTag(tag)\"\n" +
    "                            ng-class=\"{'checked-list-item': tag.name === currentTag, 'branch-disabled': tag.status == 'creating'}\"\n" +
    "                            is-open=\"false\" tooltip-placement=\"left\" uib-tooltip-html=\"htmlTooltip\"\n" +
    "                            tooltip-append-to-body=\"tag.status == 'creating'\" tooltip-enable=\"tag.status == 'creating'\">\n" +
    "                            <a ng-href=\"{{getHrefForTag(tag);}}\" ng-style=\"{display: 'block'}\"> {{ tag.name }} </a>\n" +
    "                        </li>\n" +
    "                        <li ng-if=\"!tags.length\" class=\"ve-secondary\">No Tags</li>\n" +
    "                    </uib-tab>\n" +
    "                </uib-tabset>\n" +
    "            </ul>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</nav>\n" +
    "");
}]);

angular.module("partials/mms/veNav.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("partials/mms/veNav.html",
    "<nav class=\"navbar navbar-inverse navbar-fixed-top\" role=\"navigation\">\n" +
    "    <div class=\"block\">\n" +
    "        <div class=\"navbar-header\">\n" +
    "            <button type=\"button\" class=\"navbar-toggle main-nav-toggle\" ng-click=\"isNavCollapsed = !isNavCollapsed\">\n" +
    "                <span class=\"sr-only\">Toggle navigation</span>\n" +
    "                <i class=\"fa fa-bars\" aria-hidden=\"true\"></i>\n" +
    "            </button>\n" +
    "            <span>\n" +
    "            <a class=\"navbar-brand\" ui-sref=\"login.select\">\n" +
    "                <img src=\"assets/logo.svg\" alt=\"View Editor Logo\">\n" +
    "            </a>\n" +
    "            <a ng-if=\"!org.homeLink\" class=\"org-title\" ui-sref=\"project.ref({projectId: project.id, refId: ref.id})\">{{ org.name }}</a>\n" +
    "            <a ng-if=\"org.homeLink\" class=\"org-title\" ng-href=\"{{org.homeLink}}\">{{ org.name }}</a>\n" +
    "            <a class=\"switch-org\" ng-click=\"updateOrg()\">Switch Org</a>\n" +
    "            </span>\n" +
    "        </div>\n" +
    "        <div class=\"collapse navbar-collapse\" uib-collapse=\"isNavCollapsed\" id=\"global-navigation\">\n" +
    "            <form id=\"global-search\" ng-submit=\"search(searchText)\" class=\"navbar-form navbar-left\" role=\"search\">\n" +
    "                <div class=\"form-group\">\n" +
    "                    <input placeholder=\"Search selected project\" ng-model=\"searchText\"/>\n" +
    "                    <button ng-click=\"search(searchText)\">\n" +
    "                        <i class=\"fa fa-search\" aria-hidden=\"true\"></i>\n" +
    "                    </button>\n" +
    "                </div>\n" +
    "            </form>\n" +
    "            <ul class=\"nav navbar-nav global-menu\">\n" +
    "                \n" +
    "                <li class=\"dropdown\">\n" +
    "                    <span class=\"dropdown ve-dark-dropdown-nav fixed-content-dropdown\" uib-dropdown on-toggle=\"toggled(open)\">\n" +
    "                        <a href id=\"help-dropdown\" class=\"dropdown-toggle\" uib-dropdown-toggle>\n" +
    "                        Help <i class=\"fa fa-caret-down\" aria-hidden=\"true\"></i>\n" +
    "                        </a>\n" +
    "                        <ul class=\"dropdown-menu pull-right\" uib-dropdown-menu aria-labelledby=\"help-dropdown\">\n" +
    "                            <li>\n" +
    "                                <a href=\"\" ng-click=\"toggleHelp()\">Keyboard Shortcuts</a>\n" +
    "                            </li>\n" +
    "                            <li class=\"divider\"></li>\n" +
    "                            <li>\n" +
    "                                <a href=\"https://github.com/Open-MBEE/ve/blob/develop/Documents/ViewEditorUserGuide.pdf\" target=\"_blank\">View Editor Help</a>\n" +
    "                            </li>\n" +
    "                            <li>\n" +
    "                                <a href=\"https://openmbee.atlassian.net/secure/Dashboard.jspa\" target=\"_blank\">Report Issue</a>\n" +
    "                            </li>\n" +
    "                            <li class=\"divider\"></li>\n" +
    "                            <li class=\"list-section-bottom\">\n" +
    "                                <a ng-click=\"toggleAbout()\" href=\"\">About View Editor</a>\n" +
    "                            </li>\n" +
    "                         </ul>\n" +
    "                    </span>\n" +
    "                </li>\n" +
    "                <li class=\"dropdown\">\n" +
    "                    <span class=\"dropdown ve-dark-dropdown-nav fixed-content-dropdown user-profile\" uib-dropdown on-toggle=\"toggled(open)\">\n" +
    "                        <a href id=\"login-dropdown\" class=\"dropdown-toggle\" uib-dropdown-toggle>\n" +
    "                            {{ username | limitTo:1 | uppercase }}\n" +
    "                        </a>\n" +
    "                        <ul class=\"dropdown-menu pull-right\" uib-dropdown-menu aria-labelledby=\"login-dropdown\" style=\"white-space: nowrap\" role=\"login-menu\">\n" +
    "                            <li ng-if=\"username\" class=\"ve-secondary\">\n" +
    "                                <span>Logged in as <b>{{ username }}</b></span>\n" +
    "                            </li>\n" +
    "                            <li class=\"divider\"></li>\n" +
    "                            <li>\n" +
    "                                <a ng-click=\"logout()\">Log Out</a>\n" +
    "                            </li>\n" +
    "                         </ul>\n" +
    "                    </span>\n" +
    "                </li>\n" +
    "            </ul>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</nav>\n" +
    "");
}]);
