angular.module("mms.directives.tpls", ["mms/templates/mergeConfirm.html", "mms/templates/mmsAddPeButton.html", "mms/templates/mmsAnnotation.html", "mms/templates/mmsButtonBar.html", "mms/templates/mmsCfModal.html", "mms/templates/mmsCommentModal.html", "mms/templates/mmsDiffAttr.html", "mms/templates/mmsGroupDocs.html", "mms/templates/mmsHistory.html", "mms/templates/mmsHtmlDiff.html", "mms/templates/mmsJobs.html", "mms/templates/mmsMention.html", "mms/templates/mmsRefList.html", "mms/templates/mmsSearch.html", "mms/templates/mmsSearchResults.html", "mms/templates/mmsSpec.html", "mms/templates/mmsToolbar.html", "mms/templates/mmsTranscludeDoc.html", "mms/templates/mmsTranscludeName.html", "mms/templates/mmsTranscludeVal.html", "mms/templates/mmsTranscludeValEdit.html", "mms/templates/mmsTranscludeValFrame.html", "mms/templates/mmsTree.html", "mms/templates/mmsTsDiagram.html", "mms/templates/mmsView.html", "mms/templates/mmsViewPresentationElem.html", "mms/templates/mmsViewReorder.html", "mms/templates/mmsViewSection.html", "mms/templates/revertConfirm.html", "mms/templates/saveConflict.html"]);

angular.module("mms/templates/mergeConfirm.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mergeConfirm.html",
    "<div class=\"modal-header\">\n" +
    "    <h4>Pull in changed presentation elements <span class=\"text-warning\">(beta)</span></h4>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-body revert-dialogue\">\n" +
    "    <p><b>{{docName}}:</b> Pull in changed Presentation Elements from <span class=\"{{srcRefOb.type}}-icon\"><b>{{srcRefOb.name}}</b></span> to <span class=\"{{currentRefOb.type}}-icon\"><b>{{currentRefOb.name}}</b></p>\n" +
    "\n" +
    "    <p style=\"padding-bottom:20px;\"> This will commit contents of source Presentation Elements (PEs)<a><i class=\"fa fa-question-circle\" uib-tooltip=\"Text, image, equation, and section elements\" tooltip-placement=\"bottom\"></i></a> to target PEs if source PEs have changed, and add new PEs from source to target if they do not exist on target.\n" +
    "        <!-- <a> More...</a> -->\n" +
    "    </p>\n" +
    "\n" +
    "    <div class=\"element-preview-box\">\n" +
    "        <p><i class=\"fa fa-exclamation-triangle\"></i><b>Notice:</b> Your document may not\n" +
    "            look complete unless your model merge was completed as well.\n" +
    "        </p>\n" +
    "        <p><i class=\"fa fa-exclamation-triangle\"></i><b>Before applying to your production\n" +
    "            branch</b> please <a>create a test branch</a> from your target branch, apply changes, and preview.</p>\n" +
    "    </div>\n" +
    "    <label>Optional change log message</label>\n" +
    "    <div class=\"commit-message-box\">\n" +
    "        <textarea ng-model=\"commitMessage\" style=\"width:100%\" rows=\"3\" placeholder=\"Describe your changes\"></textarea>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-warning\" ng-click=\"ok()\">Pull</button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "</div>\n" +
    "");
}]);

angular.module("mms/templates/mmsAddPeButton.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsAddPeButton.html",
    "<div class=\"mms-add-pe-button\" ng-mouseover=\"setPeLineVisibility($event);\" ng-mouseleave=\"setPeLineVisibility($event);\">\n" +
    "  <span class=\"center btn-group dropdown\" uib-dropdown>\n" +
    "    <a type=\"button\" class=\"dropdown-toggle btn btn-sm\" ng-click=\"$event.stopPropagation(); setPeLineVisibility($event);\" uib-dropdown-toggle aria-expanded=\"true\" title=\"Add cross referenceable text, tables, images, equations, sections, and comments\">\n" +
    "        <i class=\"fa fa-plus\"></i>\n" +
    "    </a>\n" +
    "    <ul class=\"dropdown-menu\" uib-dropdown-menu role=\"add PE menu\">\n" +
    "      <li ng-click=\"addEltAction($index, 'Paragraph', $event)\">\n" +
    "        <a type=\"button\" class=\"add-element-button center view-add-paragraph pe-type-Paragraph\" title=\"Add text\">\n" +
    "          <span class=\"icon-title\">Text</span>\n" +
    "        </a>\n" +
    "      </li>\n" +
    "      <li ng-click=\"addEltAction($index, 'Table', $event)\">\n" +
    "        <a type=\"button\" class=\"add-element-button center view-add-table pe-type-Table\" title=\"Add table\">\n" +
    "          <span class=\"icon-title\">Table</span>\n" +
    "        </a>\n" +
    "      </li>\n" +
    "      <li ng-click=\"addEltAction($index, 'Image', $event)\">\n" +
    "        <a type=\"button\" class=\"add-element-button center view-add-image pe-type-Image\" title=\"Add image\">\n" +
    "          <span class=\"icon-title\">Image</span>\n" +
    "        </a>\n" +
    "      </li>\n" +
    "      <li ng-click=\"addEltAction($index, 'Equation', $event)\">\n" +
    "        <a type=\"button\" class=\"add-element-button center view-add-equation pe-type-Equation\" title=\"Add equation\">\n" +
    "          <span class=\"icon-title\">Equation</span>\n" +
    "        </a>\n" +
    "      </li>\n" +
    "      <li ng-click=\"addEltAction($index, 'Section', $event)\">\n" +
    "        <a type=\"button\" class=\"add-element-button center view-add-section pe-type-Section\" title=\"Add section\">\n" +
    "          <span class=\"icon-title\">Section</span>\n" +
    "        </a>\n" +
    "      </li>\n" +
    "      <li ng-click=\"addEltAction($index, 'Comment', $event)\">\n" +
    "        <a type=\"button\" class=\"add-element-button center view-add-comment pe-type-Comment\" title=\"Add comment\">\n" +
    "          <span class=\"icon-title\">Comment</span>\n" +
    "        </a>\n" +
    "      </li>\n" +
    "    </ul>\n" +
    "  </span>\n" +
    "</div>\n" +
    "<hr/>");
}]);

angular.module("mms/templates/mmsAnnotation.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsAnnotation.html",
    "<span class=\"mms-error\" uib-popover-template=\"'annotationTemplate.html'\" popover-popup-close-delay=\"500\" ng-bind-html=\"displayContent.inlineContent\" popover-placement=\"top-left\" popover-title=\"{{displayContent.toolTipTitle}}\"></span>\n" +
    "<script type=\"text/ng-template\" id=\"annotationTemplate.html\">\n" +
    "    <p>\n" +
    "        {{displayContent.toolTipContent}}\n" +
    "    </p>\n" +
    "    <p id=\"tooltipElementId\" style=\"position: absolute; left: -1000px; top: -1000px; \">\n" +
    "        {{displayContent.id}}\n" +
    "    </p>\n" +
    "    <button ng-click=\"copyToClipboard($event)\" class=\"btn btn-sm btn-default\"><i class=\"fa fa-copy\"></i>Copy Element ID</button>\n" +
    "</script>\n" +
    "");
}]);

angular.module("mms/templates/mmsButtonBar.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsButtonBar.html",
    "<span ng-repeat=\"button in buttons | filter: {permission: true}\">\n" +
    "\n" +
    "  <!-- Normal button -->\n" +
    "  <a type=\"button\" ng-if=\"!button.dropdown_buttons\" class=\"btn btn-tools btn-sm {{button.id}}\"\n" +
    "      ng-click=\"button.action($event)\" uib-tooltip=\"{{button.tooltip}}\" tooltip-append-to-body=\"false\"\n" +
    "      tooltip-trigger=\"mouseenter\" tooltip-popup-delay=\"100\" tooltip-placement=\"{{button.placement}}\">\n" +
    "      <i class=\"fa {{button.icon}}\"></i>{{button.text}}</a>\n" +
    "\n" +
    "  <!-- Button with dropdown buttons -->\n" +
    "  <span ng-if=\"button.dropdown_buttons\" class=\"btn-group\" uib-dropdown>\n" +
    "    <button type=\"button\" class=\"btn btn-tools btn-sm dropdown-toggle {{button.id}}\" uib-dropdown-toggle uib-tooltip=\"{{button.tooltip}}\"\n" +
    "        tooltip-append-to-body=\"false\" tooltip-trigger=\"mouseenter\" tooltip-popup-delay=\"100\"\n" +
    "        tooltip-placement=\"{{button.placement}}\">\n" +
    "        <i class=\"fa {{button.icon}}\"></i>{{button.button_content}}<span class=\"caret\"></span></button>\n" +
    "    <ul class=\"dropdown-menu\" role=\"menu\">\n" +
    "      <li>\n" +
    "          <a ng-repeat=\"dropdown_button in button.dropdown_buttons | filter: {permission: true}\" type=\"button\"\n" +
    "              class=\"center {{dropdown_button.id}} {{ button.id === 'view-mode-dropdown' && dropdown_button.selected ? 'checked-list-item' : ''}}\" ng-click=\"dropdown_button.action($event); mmsBbApi.select(button, dropdown_button)\"><i\n" +
    "              class=\"fa {{dropdown_button.icon}}\"> </i>&nbsp;{{dropdown_button.tooltip}}</a>\n" +
    "      </li>\n" +
    "    </ul>\n" +
    "  </span>\n" +
    "\n" +
    "</span>\n" +
    "");
}]);

angular.module("mms/templates/mmsCfModal.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsCfModal.html",
    "<div class=\"modal-header\">\n" +
    "    <h4>{{title}}</h4>\n" +
    "</div>\n" +
    "<div class=\"modal-body\">\n" +
    "    <div class=\"ve-light-tabs modal-top-tabs\" ng-show=\"showProposeLink\">\n" +
    "        <ul class=\"nav nav-tabs\">\n" +
    "            <li class=\"uib-tab nav-item tab-item\" ng-class=\"{'active': !searchExisting}\">\n" +
    "                <a class=\"nav-link\" ng-click=\"searchExisting = false\"><i class=\"fa fa-plus\"></i>Create New</a>\n" +
    "            </li>\n" +
    "            <li class=\"uib-tab nav-item tab-item\" ng-class=\"{'active': searchExisting}\">\n" +
    "                <a class=\"nav-link\" ng-click=\"searchExisting = true\"><i class=\"fa fa-search\"></i>Search for existing</a>\n" +
    "            </li>\n" +
    "        </ul>\n" +
    "    </div>\n" +
    "\n" +
    "    <!-- Seach for exisiting panel -->\n" +
    "    <div ng-show=\"searchExisting\">\n" +
    "        <div class=\"transclude-modal-instructions\">\n" +
    "            {{description}}\n" +
    "        </div>\n" +
    "        <div class=\"form-group\" ng-show=\"!showProposeLink\"><br>\n" +
    "            <label>Link Text:</label>\n" +
    "            <div class=\"radio radio-with-label\">\n" +
    "                <label><input type=\"radio\" ng-model=\"linkType\" ng-value=\"1\">&nbsp;Document auto-numbering\n" +
    "                <a uib-tooltip=\"For links within current document, otherwise defaults to name\" tooltip-trigger=\"mouseenter\" tooltip-popup-delay=\"100\"><i class=\"fa fa-info-circle\"></i></a></label><br>\n" +
    "                <label><input type=\"radio\" ng-model=\"linkType\" ng-value=\"2\">&nbsp;Name</label><br>\n" +
    "                <label><input type=\"radio\" ng-model=\"linkType\" ng-value=\"3\">&nbsp;Custom&nbsp;\n" +
    "                    <input type=\"text\" ng-model=\"linkText\" placeholder=\"custom title\"/></label>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <label ng-show=\"showEditableOp\" for=\"nonEditableCheckbox\">\n" +
    "            <input id=\"nonEditableCheckbox\" type=\"checkbox\" ng-model=\"nonEditableCheckbox\">&nbsp;Restrict editing\n" +
    "        </label>\n" +
    "\n" +
    "        <mms-search mms-options=\"searchOptions\" mms-project-id=\"{{mmsProjectId}}\" mms-ref-id=\"{{mmsRefId}}\"></mms-search>\n" +
    "    </div>\n" +
    "\n" +
    "    <!-- Create New Panel -->\n" +
    "    <div ng-show=\"!searchExisting\">\n" +
    "        <form>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label>Name </label><span class=\"star-mandatory\">*</span>\n" +
    "                <input class=\"form-control\" type=\"text\" ng-model=\"newE.name\" placeholder=\"Name your new element\" autofocus/>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"label-documentation\">Documentation</label>\n" +
    "                <textarea mms-ckeditor ng-model=\"newE.documentation\" mms-project-id=\"{{mmsProjectId}}\" mms-ref-id=\"{{mmsRefId}}\" class=\"textarea-transclude-modal\"></textarea>\n" +
    "            </div>\n" +
    "            <label>Property to cross-reference</label><span class=\"star-mandatory\">*</span>\n" +
    "            <div class=\"radio radio-with-label\">\n" +
    "                <label><input type=\"radio\" name=\"optradio\" value=\"true\" ng-click=\"toggleRadio('name')\">Name</label><br>\n" +
    "                <label><input type=\"radio\" name=\"optradio\" value=\"true\" ng-click=\"toggleRadio('documentation')\">Documentation</label>\n" +
    "            </div>\n" +
    "        </form>\n" +
    "\n" +
    "        <p class=\"help-block pull-left\"><i>Fields marked with <span class=\"star-mandatory\">*</span> are required</i></p>\n" +
    "    </div>\n" +
    "</div>\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-primary\" ng-show=\"!searchExisting\" type=\"button\" ng-click=\"makeNewAndChoose()\">Create & Cross Reference<i class=\"{{proposeClass}}\"></i></button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "</div>");
}]);

angular.module("mms/templates/mmsCommentModal.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsCommentModal.html",
    "<div class=\"modal-header\">\n" +
    "    <h4>Insert a comment</h4>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-body comment-modal\">\n" +
    "    <div class=\"comment-modal-input\">\n" +
    "        <div class=\"form-group\">\n" +
    "            <label>Title</label>\n" +
    "            <input class=\"form-control\" ng-model=\"comment.name\" type=\"text\"\n" +
    "                   placeholder=\"Type a title for your comment here\" autofocus>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "            <label>Comment</label>\n" +
    "            <textarea class=\"form-control\" rows=\"3\" ng-model=\"comment.documentation\" \n" +
    "                placeholder=\"Type your comment here\" ng-keyup=\"$event.keyCode == 13 ? ok(comment) : null\"></textarea>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-primary\" ng-click=\"ok(comment)\">Create <i ng-show=\"oking\" class=\"fa fa-spin fa-spinner\"></i>\n" +
    "    </button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "</div>");
}]);

angular.module("mms/templates/mmsDiffAttr.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsDiffAttr.html",
    "<span ng-show=\"diffLoading\">\n" +
    "    <i class=\"fa fa-spin fa-spinner\"></i>\n" +
    "</span>\n" +
    "<span ng-hide=\"diffLoading\">\n" +
    "    <span class=\"text-info\" ng-if=\"message\"><i class=\"fa fa-info-circle\"></i>{{message}}</span>\n" +
    "    <mms-html-diff ng-if=\"baseElementHtml != undefined && comparedElementHtml != undefined\" mms-base-html=\"baseElementHtml\" mms-compared-html=\"comparedElementHtml\" mms-diff-finish=\"diffFinish\"></mms-html-diff>\n" +
    "</span>");
}]);

angular.module("mms/templates/mmsGroupDocs.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsGroupDocs.html",
    "<table class=\"table table-condensed\">\n" +
    "    <tr><th>Document(s)</th><!--<th>Last Modified</th><th>Last Modified By</th><th>Created</th>--></tr>\n" +
    "    <tr ng-repeat=\"doc in docs | orderBy: 'name'\">\n" +
    "        <td><mms-view-link mms-document-id=\"{{doc.id}}\" mms-element-id=\"{{doc.id}}\" mms-project-id=\"{{projectId}}\" mms-ref-id=\"{{refId}}\"></mms-view-link></td>\n" +
    "    </tr>\n" +
    "</table>");
}]);

angular.module("mms/templates/mmsHistory.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsHistory.html",
    "<h4 class=\"right-pane-title\">Element History\n" +
    "    <span ng-show=\"gettingHistory\"><i class=\"fa fa-spin fa-spinner\"></i></span>\n" +
    "</h4>\n" +
    "\n" +
    "<div style=\"margin-bottom:6px;\">\n" +
    "    <input type=\"checkbox\" style=\"margin-right:4px;\" ng-model=\"ModelData.ShowDiffPrototype\" ng-disabled=\"disableCompare\">Compare versions\n" +
    "</div>\n" +
    "\n" +
    "<div style=\"position:relative;\">\n" +
    "    <div ng-if=\"ModelData.ShowDiffPrototype\" class=\"inline-diff-buttons fade-in-out\">\n" +
    "        <span class=\"inline-btn-label\">Before:</span>\n" +
    "        <div class=\"btn-group ve-light-dropdown\" uib-dropdown keyboard-nav is-open=\"baseCommit.isopen\" auto-close=\"outsideClick\" style=\"flex:2\" ng-hide=\"disableRevert\">\n" +
    "            <button class=\"dropdown-toggle\" type=\"button\" uib-dropdown-toggle>\n" +
    "                {{baseCommit.commitSelected._created | date:'M/d/yy h:mm a'}} by <b>{{baseCommit.commitSelected._creator}}</b><span ng-if=\"baseCommit.commitSelected.id === baseCommit.baseHistory[0].id\"> (Latest)</span>\n" +
    "                <i class=\"fa fa-caret-down\" aria-hidden=\"true\"></i>\n" +
    "            </button>\n" +
    "            <ul class=\"dropdown-menu menu-with-input\" uib-dropdown-menu role=\"menu\">\n" +
    "                <li class=\"dropdown-input\">\n" +
    "                    <input class=\"ve-plain-input\" ng-model=\"compareHistoryFilter\" type=\"text\" placeholder=\"Filter history on {{baseCommit.refSelected.type}}: {{baseCommit.refSelected.name}}\">\n" +
    "                </li>\n" +
    "                <li ng-click=\"baseCommitClicked(version)\" ng-repeat=\"version in baseCommit.baseHistory | filter:compareHistoryFilter\"\n" +
    "                    ng-class=\"{'checked-list-item': version === baseCommit.commitSelected,'secondary-checked-list-item': version === compareCommit.commitSelected}\"\n" +
    "                    ng-disabled=\"$index === 0\" tooltip-placement=\"left\" uib-tooltip=\"Selected after version\"\n" +
    "                    tooltip-append-to-body=\"version === compareCommit.commitSelected\" tooltip-enable=\"version === compareCommit.commitSelected\">\n" +
    "                    <a>{{version._created | date:'M/d/yy h:mm a'}} by <b>{{ version._creator }}</b><span ng-if=\"$index == 0\"> (Latest)</span></a>\n" +
    "                </li>\n" +
    "            </ul>\n" +
    "        </div>\n" +
    "\n" +
    "        <span class=\"inline-btn-label\" style=\"flex:2;text-align:right;\" ng-show=\"disableRevert\">Element does not exist</span>\n" +
    "\n" +
    "        <span class=\"inline-btn-label\"> on</span>\n" +
    "\n" +
    "        <div class=\"btn-group ve-light-dropdown\" uib-dropdown keyboard-nav is-open=\"baseCommit.refisopen\" auto-close=\"outsideClick\" style=\"flex:1\">\n" +
    "            <button class=\"dropdown-toggle\" type=\"button\" uib-dropdown-toggle>\n" +
    "                {{baseCommit.refSelected.type}}: <b>{{baseCommit.refSelected.name}}</b>\n" +
    "                <i class=\"fa fa-caret-down\" aria-hidden=\"true\"></i>\n" +
    "            </button>\n" +
    "            <ul class=\"dropdown-menu menu-with-input pull-right\" uib-dropdown-menu role=\"menu\">\n" +
    "                <li class=\"dropdown-input\">\n" +
    "                    <input class=\"ve-plain-input\" type=\"text\" placeholder=\"Filter branches/tags\" ng-model=\"refFilter\">\n" +
    "                </li>\n" +
    "                <li ng-click=\"getElementHistoryByRef(ref)\" ng-repeat=\"ref in refList | filter:refFilter\"\n" +
    "                    ng-class=\"{'checked-list-item': ref.id === baseCommit.refSelected.id}\">\n" +
    "                    <a>{{ ref.type }}: <b>{{ref.name}}</b></a>\n" +
    "                </li>\n" +
    "            </ul>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"diff-dotted-connection fade-in-out\" ng-show=\"ModelData.ShowDiffPrototype\"></div>\n" +
    "    <div class=\"inline-diff-buttons\">\n" +
    "        <span class=\"inline-btn-label fade-in-out\" ng-show=\"ModelData.ShowDiffPrototype\">After:</span>\n" +
    "        <div class=\"btn-group ve-light-dropdown\" ng-class=\"{'flex-grow-shrink':ModelData.ShowDiffPrototype}\" uib-dropdown keyboard-nav is-open=\"compareCommit.isopen\" auto-close=\"outsideClick\" style=\"flex:2\">\n" +
    "            <button class=\"dropdown-toggle\" type=\"button\" uib-dropdown-toggle>\n" +
    "                <span>{{compareCommit.commitSelected._created | date:'M/d/yy h:mm a'}} by <b>{{compareCommit.commitSelected._creator}}</b></span>\n" +
    "                <span ng-if=\"compareCommit.commitSelected.id === compareCommit.compareHistory[0].id\"> (Latest)</span>\n" +
    "                <i class=\"fa fa-caret-down\" aria-hidden=\"true\"></i>\n" +
    "            </button>\n" +
    "            <ul class=\"dropdown-menu menu-with-input\" uib-dropdown-menu role=\"menu\">\n" +
    "                <li class=\"dropdown-input\">\n" +
    "                    <input class=\"ve-plain-input\" type=\"text\" placeholder=\"Filter history on {{compareCommit.ref.type}}: {{compareCommit.ref.name}}\" ng-model=\"historyFilter\">\n" +
    "                </li>\n" +
    "                <li ng-click=\"commitClicked(version)\" ng-repeat=\"version in compareCommit.compareHistory | filter:historyFilter\"\n" +
    "                    ng-class=\"{'checked-list-item': version === compareCommit.commitSelected,'secondary-checked-list-item': version === baseCommit.commitSelected && ModelData.ShowDiffPrototype}\"\n" +
    "                    tooltip-placement=\"left\" uib-tooltip=\"Selected before version\"\n" +
    "                    tooltip-append-to-body=\"version === baseCommit.commitSelected\" tooltip-enable=\"ModelData.ShowDiffPrototype && version === baseCommit.commitSelected\">\n" +
    "                    <a>{{version._created | date:'M/d/yy h:mm a'}} by <b>{{ version._creator }}</b><span ng-if=\"$index == 0\"> (Latest)</span></a>\n" +
    "                </li>\n" +
    "            </ul>\n" +
    "        </div>\n" +
    "        <span class=\"inline-btn-label\" ng-show=\"ModelData.ShowDiffPrototype\">on</span>\n" +
    "        <span class=\"inline-btn-label fade-in-out\" ng-show=\"ModelData.ShowDiffPrototype\" style=\"flex:1\">{{compareCommit.ref.type}}: <b>{{compareCommit.ref.name}}</b></span>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<hr class=\"spec-title-divider\">\n" +
    "\n" +
    "<div ng-if=\"ModelData.ShowDiffPrototype\">\n" +
    "    <div class=\"ve-notify-banner fade-in-out\" ng-show=\"disableRevert\">\n" +
    "        <span>Element does not exist on <strong>{{baseCommit.refSelected.type}}: {{baseCommit.refSelected.name}}</strong></span>\n" +
    "    </div>\n" +
    "    <div ng-hide=\"disableRevert\">\n" +
    "        <h1 class=\"prop element-title\">\n" +
    "            <mms-diff-attr mms-attr=\"name\"\n" +
    "                           mms-base-project-id=\"{{mmsProjectId}}\"\n" +
    "                           mms-base-ref-id=\"{{baseCommit.refSelected.id}}\"\n" +
    "                           mms-base-commit-id=\"{{baseCommit.commitSelected.id}}\"\n" +
    "                           mms-base-element-id=\"{{mmsElementId}}\"\n" +
    "                           mms-compare-ref-id=\"{{mmsRefId}}\"\n" +
    "                           mms-compare-commit-id=\"{{compareCommit.commitSelected.id}}\">\n" +
    "            </mms-diff-attr>\n" +
    "        </h1>\n" +
    "\n" +
    "        <h2 class=\"prop-title spec-view-doc-heading\">Documentation</h2>\n" +
    "        <mms-diff-attr mms-attr=\"doc\"\n" +
    "                       mms-base-project-id=\"{{mmsProjectId}}\"\n" +
    "                       mms-base-ref-id=\"{{baseCommit.refSelected.id}}\"\n" +
    "                       mms-base-commit-id=\"{{baseCommit.commitSelected.id}}\"\n" +
    "                       mms-base-element-id=\"{{mmsElementId}}\"\n" +
    "                       mms-compare-ref-id=\"{{mmsRefId}}\"\n" +
    "                       mms-compare-commit-id=\"{{compareCommit.commitSelected.id}}\">\n" +
    "        </mms-diff-attr>\n" +
    "\n" +
    "        <div ng-if=\"element.type === 'Property' || element.type === 'Port' || element.type === 'Slot'\">\n" +
    "            <h2 class=\"prop-title\">Property Value</h2>\n" +
    "            <span class=\"prop\">\n" +
    "                <mms-diff-attr mms-attr=\"val\"\n" +
    "                               mms-base-project-id=\"{{mmsProjectId}}\"\n" +
    "                               mms-base-ref-id=\"{{baseCommit.refSelected.id}}\"\n" +
    "                               mms-base-commit-id=\"{{baseCommit.commitSelected.id}}\"\n" +
    "                               mms-base-element-id=\"{{mmsElementId}}\"\n" +
    "                               mms-compare-ref-id=\"{{mmsRefId}}\"\n" +
    "                               mms-compare-commit-id=\"{{compareCommit.commitSelected.id}}\">\n" +
    "                </mms-diff-attr>\n" +
    "            </span>\n" +
    "        </div>\n" +
    "        <h2 class=\"prop-title\">Commit</h2>\n" +
    "        <span class=\"prop\">{{baseCommit.commitSelected.id}}<br>\n" +
    "        <img src=\"assets/images/arrow-change.svg\" class=\"change-connection\"><br>\n" +
    "        {{compareCommit.commitSelected.id}}</span>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<!-- Spec window -->\n" +
    "<div ng-hide=\"ModelData.ShowDiffPrototype\">\n" +
    "    <mms-spec mms-element-id=\"{{mmsElementId}}\" mms-project-id=\"{{mmsProjectId}}\" mms-ref-id=\"{{mmsRefId}}\" mms-commit-id=\"{{historyVer}}\" no-edit=\"true\"></mms-spec>\n" +
    "</div>\n" +
    "\n" +
    "<div ng-show=\"ModelData.ShowDiffPrototype && compareCommit.ref.type === 'Tag'\" class=\"revert-section fade-in-out\">\n" +
    "    <div>\n" +
    "        <span class=\"ve-secondary-text\">Cannot make changes on a <b>Tag</b>.</span>\n" +
    "    </div>\n" +
    "</div>\n" +
    "<div ng-show=\"ModelData.ShowDiffPrototype && !disableRevert && compareCommit.ref.type !== 'Tag'\" class=\"revert-section fade-in-out\">\n" +
    "    <div>\n" +
    "        <span>To revert <b>documentation</b>, <b>name</b>, and <b>value</b> to before content ({{baseCommit.commitSelected._created | date:'M/d/yy h:mm a'}} by <b>{{baseCommit.commitSelected._creator}}</b>):</span>\n" +
    "        <button class=\"btn btn-warning btn-sm pull-right\" ng-click=\"revert()\">Revert</button>\n" +
    "    </div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("mms/templates/mmsHtmlDiff.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsHtmlDiff.html",
    "<div id=\"{{htmlDiffId}}\" class=\"htmlDiff\" ng-bind-html=\"diffResult\"></div>\n" +
    "");
}]);

angular.module("mms/templates/mmsJobs.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsJobs.html",
    "<h4 class=\"right-pane-title\">Jobs</h4>\n" +
    "<span ng-if=\"loadingJobs\"><i class=\"fa fa-spin fa-spinner\"></i></span>\n" +
    "<hr class=\"spec-title-divider\">\n" +
    "\n" +
    "<div id=\"ve-jobs-table\">\n" +
    "    <h3>View Editor</h3>\n" +
    "    <div ng-if=\"!refList.length\" class=\"text-center well empty-state\">\n" +
    "    <h4>No Current VE Jobs</h4>\n" +
    "    </div>\n" +
    "    <table class=\"table table-condensed\" ng-if=\"refList.length\">\n" +
    "        <thead>\n" +
    "            <tr>\n" +
    "                <td>Name</td>\n" +
    "                <td>Status</td>\n" +
    "                <td>Created</td>\n" +
    "            </tr>\n" +
    "        </thead>\n" +
    "        <tbody>\n" +
    "            <tr ng-repeat=\"ref in refList\">\n" +
    "                <td>{{ref.type}}: {{ref.name}}</td>\n" +
    "                <td>\n" +
    "                    <div ng-switch=\"ref.status\">\n" +
    "                        <span ng-switch-when=\"creating\">\n" +
    "                            <i class=\"fa fa-ellipsis-h in-progress-marker\" aria-hidden=\"true\"></i>{{ref.status}} \n" +
    "                        </span>\n" +
    "                        <span ng-switch-when=\"fail\">\n" +
    "                            <i class=\"fa fa-circle failed-marker\" aria-hidden=\"true\"></i>{{ref.status}} \n" +
    "                        </span>\n" +
    "                        <span ng-switch-when=\"created\">\n" +
    "                            <i class=\"fa fa-circle completed-marker\" aria-hidden=\"true\"></i>{{ref.status}}\n" +
    "                        </span>\n" +
    "                    </div>\n" +
    "                </td>\n" +
    "                <td>{{ref._created | date:'M/d/yy h:mm a'}}</td>\n" +
    "            </tr>\n" +
    "        </tbody>\n" +
    "    </table><br>\n" +
    "\n" +
    "\n" +
    "    <h3>PMA (Platform for Modeling and Analysis)</h3>\n" +
    "    <table class=\"table table-condensed\" ng-if=\"!loadingJobs && jobs.length\">\n" +
    "        <thead>\n" +
    "            <tr>\n" +
    "                <td>Name</td>\n" +
    "                <!--<td>Created</td>-->\n" +
    "                <td>Status</td>\n" +
    "                <td>Actions</td>\n" +
    "            </tr>\n" +
    "        </thead>\n" +
    "        <tbody>\n" +
    "            <tr ng-repeat=\"job in jobs\">\n" +
    "                <td>\n" +
    "                    <div ng-hide=\"editorEnabled\">\n" +
    "                        <b>{{job.name}}</b>\n" +
    "                        <!--<a class=\"ng-binding\" ng-click=\"enableEditor()\">-->\n" +
    "                            <!--<i class=\"fa fa-pencil\" aria-hidden=\"true\"></i>-->\n" +
    "                        <!--</a>-->\n" +
    "                    </div>\n" +
    "                    <!--<div ng-show=\"editorEnabled\">\n" +
    "                        <input ng-model=\"jobInput.jobName\" ng-show=\"editorEnabled\">\n" +
    "                        <a ng-click=\"save()\">Save</a>\n" +
    "                        or\n" +
    "                        <a ng-click=\"disableEditor()\">Cancel</a>\n" +
    "                    </div>-->\n" +
    "                </td>\n" +
    "                <!--<td>\n" +
    "                    <select class = \"form-control\">\n" +
    "                        {{job.create}}\n" +
    "                    </select>\n" +
    "                </td>-->\n" +
    "                <td>\n" +
    "                    <div ng-if=\"loading\">\n" +
    "                        <span><i class=\"fa fa-spinner fa-spin\"></i>&nbsp;Loading...</span>\n" +
    "                    </div>\n" +
    "                    <div ng-show=\"job.latestStatus || (jobInstances[job.id] && jobInstances[job.id].length)\">\n" +
    "                        <b>Build {{jobInstances[job.id][0].buildNumber}}</b>\n" +
    "                        <div class=\"build-details\" ng-switch=\"jobInstances[job.id][0].jobStatus\">\n" +
    "                            <span ng-switch-when=\"pending|loading|running|started\" ng-switch-when-separator=\"|\">\n" +
    "                                Started {{jobInstances[job.id][0].started | date:'M/d/yy h:mm a'}}<br>\n" +
    "                                <i class=\"fa fa-ellipsis-h in-progress-marker\" aria-hidden=\"true\"></i>in progress\n" +
    "                            </span>\n" +
    "                            <span ng-switch-when=\"failed\">\n" +
    "                                Completed {{jobInstances[job.id][0].completed | date:'M/d/yy h:mm a'}}<br>\n" +
    "                                <i class=\"fa fa-circle failed-marker\" aria-hidden=\"true\"></i>failed\n" +
    "                            </span>\n" +
    "                            <span ng-switch-when=\"completed\">\n" +
    "                                Completed {{jobInstances[job.id][0].completed | date:'M/d/yy h:mm a'}}<br>\n" +
    "                                <i class=\"fa fa-circle completed-marker\" aria-hidden=\"true\"></i>success\n" +
    "                            </span>\n" +
    "                            <span class=\"animate-switch\" ng-switch-default>{{jobInstances[job.id][0].jobStatus}}</span>\n" +
    "                            <a ng-attr-href=\"{{jobInstances[job.id][0].logUrl}}\" target=\"_blank\" rel=\"noopener noreferrer\" ng-show=\"jobInstances[job.id][0].logUrl\">log</a>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div ng-if=\"(!jobInstances[job.id] || !jobInstances[job.id].length) && !loading\">\n" +
    "                        <span>No runs</span>\n" +
    "                    </div>\n" +
    "                </td>\n" +
    "                <td>\n" +
    "                    <div ng-if=\"jobInstances[job.id][0].type === 'docmerge'\">\n" +
    "                        <a ng-click=\"changePane('gotoTagsBranches')\">Go to branches/tags pane</a>\n" +
    "                    </div>\n" +
    "                    <div ng-switch=\"jobInstances[job.id][0].jobStatus\" ng-if=\"jobInstances[job.id][0].type != 'docmerge'\">\n" +
    "                        <span ng-switch-when=\"pending|loading|running|started\" ng-switch-when-separator=\"|\">\n" +
    "                        <button class=\"btn btn-primary btn-sm disabled\">\n" +
    "                            Run Now <i ng-show=\"!runCleared\" class=\"fa fa-spin fa-spinner\"></i>\n" +
    "                        </button>\n" +
    "                        </span>\n" +
    "                        <span ng-switch-default>\n" +
    "                            <button class=\"btn btn-primary btn-sm\" ng-class=\"{'disabled': !docEditable || !runCleared || loading}\" ng-click=\"!runCleared || createJobandRun(job.id)\">\n" +
    "                                Run Now <i ng-show=\"!runCleared\" class=\"fa fa-spin fa-spinner\"></i>\n" +
    "                            </button>\n" +
    "                        </span>\n" +
    "                    </div>\n" +
    "                </td>\n" +
    "            </tr>\n" +
    "        </tbody>\n" +
    "    </table>\n" +
    "    <div ng-if=\"!docgenExists && !loadingJobs\" class=\"text-center well empty-state\">\n" +
    "        <h4 ng-if=\"!jobs.length && !loadingJobs\">No Current PMA Jobs</h4>\n" +
    "        <p><i>What's PMA?</i> PMA regenerates views for the current document. \n" +
    "        <a target=\"_blank\" href=\"https://wiki.jpl.nasa.gov/display/opencae/View+Editor+User+Guide%3A+5+Jobs\">More</a></p>\n" +
    "        <p ng-if=\"mmsRefType == 'Tag'\">PMA is not available on tags.</p>\n" +
    "        <p ng-if=\"!isDoc\">Please select a document.</p>\n" +
    "        <button class=\"btn btn-primary\" ng-if=\"mmsRefType != 'Tag' && isDoc\" ng-click=\"!createJobCleared || createJobandRun()\" ng-class=\"{'disabled': !docEditable || !runCleared || !createJobCleared}\">\n" +
    "            Run DocGen <i ng-hide=\"createJobCleared\" class=\"fa fa-spin fa-spinner\"></i>\n" +
    "        </button>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "");
}]);

angular.module("mms/templates/mmsMention.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsMention.html",
    "<script type=\"text/ng-template\" id=\"customTemplate.html\">\n" +
    "    <a class=\"itemContainer\" ng-switch=\"match.model.type\">\n" +
    "        <span ng-switch-when=\"name\" class=\"nameContainer\">\n" +
    "            <div class=\"leftItem\">\n" +
    "                <i class=\"icon\" ng-class=\"match.model.iconClass\"></i>\n" +
    "                <span> name </span>\n" +
    "                <span id=\"{{match.model.id + match.model.type}}\" class=\"matchName mentionMatch\" ng-bind-html=\"match.model.name | underlineMatch:query\"></span>\n" +
    "                <br>\n" +
    "            </div>\n" +
    "            <div class=\"rightItem\">\n" +
    "                <span class=\"elementType\">{{match.model.elementType}}</span>\n" +
    "                <span>last mod {{match.model.editTime}} by\n" +
    "                    <span class=\"editor\">{{match.model.editor}}</span>\n" +
    "                </span>\n" +
    "            </div>\n" +
    "        </span>\n" +
    "        <span ng-switch-when=\"doc\" class=\"contentContainer\">\n" +
    "            <span> documentation </span>\n" +
    "            <span id=\"{{match.model.id + match.model.type}}\" class=\"content mentionMatch\">{{match.model.documentation}}</span>\n" +
    "        </span>\n" +
    "        <div ng-switch-when=\"val\" class=\"contentContainer\" ng-if=\"match.model.type === 'val'\">\n" +
    "            <span> value </span>\n" +
    "            <span id=\"{{match.model.id + match.model.type}}\" class=\"content mentionMatch\">{{match.model.value}}</span>\n" +
    "        </div>\n" +
    "    </a>\n" +
    "</script>\n" +
    "\n" +
    "<div class=\"mmsMention\" id={{mmsMentionId}}>\n" +
    "    <input style=\"display:none;\" mms-mention-intercept mms-mention-intercept-value=\"mmsMentionValue\"\n" +
    "           uib-typeahead=\"mentionItem as mentionItem.name for mentionItem in fastCfListing | filter:{name: $viewValue} | limitTo:10\"\n" +
    "           typeahead-template-url=\"customTemplate.html\" typeahead-on-select='selectMentionItem($item, $model)'\n" +
    "           ng-model=\"selected\" class=\"autocomplete-modal-typeahead form-control\"\n" +
    "           placeholder=\"Type an element name to Cf\" autofocus/>\n" +
    "</div>\n" +
    "");
}]);

angular.module("mms/templates/mmsRefList.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsRefList.html",
    "<input class=\"form-control\" ng-model=\"refFilter\" type=\"text\" placeholder=\"Filter branches/tags\">\n" +
    "\n" +
    "<table class=\"tags-table table table-condensed\">\n" +
    "    <thead>\n" +
    "        <tr>\n" +
    "            <td><h3 class=\"Tag-icon\">Tag</h3></td>\n" +
    "            <td><h3>Created</h3></td>\n" +
    "            <td ng-if=\"isDoc && docEditable && showMerge\"></td>\n" +
    "        </tr>\n" +
    "    </thead>\n" +
    "    <tbody ng-show=\"filteredTags.length\">\n" +
    "        <tr ng-repeat=\"tag in filteredTags = (mmsTags | orderBy:'-_created' | filter: {name : refFilter})\">\n" +
    "            <td>\n" +
    "                <a ui-sref=\"{refId: tag.id}\"><b>{{tag.name}}</b></a>\n" +
    "                <div>{{tag.description}}</div>\n" +
    "            </td>\n" +
    "            <td class=\"ve-secondary-text\">{{tag._created | date:'M/d/yy h:mm a'}}</td>\n" +
    "            <td ng-if=\"isDoc && docEditable && showMerge\">\n" +
    "                <div class=\"btn-group\" uib-dropdown is-open=\"status.isopen\">\n" +
    "                    <button type=\"button\" class=\"btn btn-default\" uib-dropdown-toggle ng-disabled=\"disabled\" title=\"Tag actions\">\n" +
    "                        <i class=\"fa fa-ellipsis-v\"></i>\n" +
    "                    </button>\n" +
    "                    <ul class=\"dropdown-menu pull-right\" uib-dropdown-menu role=\"menu\" aria-labelledby=\"single-button\">\n" +
    "                        <li role=\"menuitem\" style=\"padding:10px;\">\n" +
    "                            <button class=\"btn btn-default btn-sm\" ng-class=\"{'disabled': !docEditable || !runCleared}\" ng-click=\"docMergeAction(tag)\">\n" +
    "                                Pull In<i ng-show=\"!runCleared\" class=\"fa fa-spin fa-spinner\"></i>\n" +
    "                            </button> to this document on current branch\n" +
    "                        </li>\n" +
    "                    </ul>\n" +
    "                </div>\n" +
    "            </td>\n" +
    "        </tr>\n" +
    "    </tbody>\n" +
    "\n" +
    "    <tbody ng-show=\"mmsTags.length && !filteredTags.length\"><tr><td colspan=\"3\" class=\"ve-secondary-text\">No tags found</td></tr></tbody>\n" +
    "\n" +
    "    <tbody ng-hide=\"mmsTags.length\"><tr><td colspan=\"3\" class=\"ve-secondary-text\">No tags in current project.</td></tr></tbody>\n" +
    "\n" +
    "    <thead>\n" +
    "        <tr>\n" +
    "            <td><h3 class=\"Branch-icon\">Branch</h3></td>\n" +
    "            <td><h3>Created</h3></td>\n" +
    "            <td ng-if=\"isDoc && docEditable && showMerge\"></td>\n" +
    "        </tr>\n" +
    "    </thead>\n" +
    "    <tbody ng-show=\"filteredBranches.length\">\n" +
    "        <tr ng-repeat=\"branch in filteredBranches = (mmsBranches | orderBy:'-_created' | filter: {name : refFilter})\">\n" +
    "            <td>\n" +
    "                <a ui-sref=\"{refId: branch.id}\"><b>{{branch.name}}</b></a>\n" +
    "                <div>{{branch.description}}</div>\n" +
    "            </td>\n" +
    "            <td class=\"ve-secondary-text\">{{branch._created | date:'M/d/yy h:mm a'}}</td>\n" +
    "            <td ng-if=\"isDoc && docEditable && showMerge\">\n" +
    "                <div class=\"btn-group\" uib-dropdown is-open=\"status.isopen\">\n" +
    "                    <button type=\"button\" class=\"btn btn-default\" uib-dropdown-toggle ng-disabled=\"disabled\" title=\"Branch actions\">\n" +
    "                        <i class=\"fa fa-ellipsis-v\"></i>\n" +
    "                    </button>\n" +
    "                    <ul class=\"dropdown-menu pull-right\" uib-dropdown-menu role=\"menu\" aria-labelledby=\"single-button\">\n" +
    "                        <li role=\"menuitem\" style=\"padding:10px;\">\n" +
    "                            <button class=\"btn btn-default btn-sm\" ng-class=\"{'disabled': !docEditable || !runCleared}\" ng-click=\"docMergeAction(branch)\">\n" +
    "                                Pull In<i ng-show=\"!runCleared\" class=\"fa fa-spin fa-spinner\"></i>\n" +
    "                            </button> to this document on current branch\n" +
    "                        </li>\n" +
    "                    </ul>\n" +
    "                </div>\n" +
    "            </td>\n" +
    "        </tr>\n" +
    "    </tbody>\n" +
    "    <tbody><tr ng-hide=\"filteredBranches.length\"><td colspan=\"3\" class=\"ve-secondary-text\">No branches found</td></tr></tbody>\n" +
    "</table>");
}]);

angular.module("mms/templates/mmsSearch.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsSearch.html",
    "<div class=\"mms-search\">\n" +
    "    <div class=\"search-header\" ng-class=\"{searchresults : searchResults}\">\n" +
    "        <div class=\"mms-search-input\" ng-hide=\"advanceSearch || advancedSearchResults\">\n" +
    "            <form class=\"form-inline basic-search-form\" ng-submit=\"newSearch(mainSearch)\">\n" +
    "                <!-- search type menu -->\n" +
    "                <div class=\"form-group fixed-content-m\">\n" +
    "                    <label class=\"sr-only\" for=\"searchTypeSelect\">Search type menu</label>\n" +
    "                    <div class=\"btn-group ve-light-dropdown\" uib-dropdown keyboard-nav is-open=\"mainSearchType.isopen\">\n" +
    "                        <button id=\"searchTypeSelect\" class=\"dropdown-toggle\" type=\"button\" uib-dropdown-toggle>\n" +
    "                            {{mainSearch.searchType.label}}&nbsp;<i class=\"fa fa-caret-down\" aria-hidden=\"true\"></i>\n" +
    "                        </button>\n" +
    "                        <ul class=\"dropdown-menu\" uib-dropdown-menu role=\"menu\" aria-labelledby=\"searchTypeSelect\">\n" +
    "                            <li ng-click=\"mainSearch.searchType = type;\" ng-repeat=\"type in fieldTypeList\"\n" +
    "                                ng-class=\"{'checked-list-item': type.id === mainSearch.searchType.id}\">\n" +
    "                                <a>{{ type.label }}</a>\n" +
    "                            </li>\n" +
    "                        </ul>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <!-- search keyword input -->\n" +
    "                <div class=\"form-group\" ng-show=\"mainSearch.searchType.id != 'metatype'\">\n" +
    "                    <label class=\"sr-only\" for=\"searchInput\">Search keyword</label>\n" +
    "                    <input class=\"search-input\" type=\"text\" id=\"searchInput\" ng-model=\"mainSearch.searchText\" autofocus/>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\" ng-if=\"mainSearch.searchType.id === 'metatype'\">\n" +
    "                    <label class=\"sr-only\" for=\"searchMetatypeSelect\">Search metatype menu</label>\n" +
    "                    <div id=\"searchMetatypeSelect\" ng-dropdown-multiselect=\"\" options=\"metatypeList\" selected-model=\"mainSearch.selectedSearchMetatypes\" extra-settings=\"metatypeSettings\"></div>\n" +
    "                </div>\n" +
    "                <button class=\"btn btn-primary\" type=\"button\" ng-click=\"newSearch(mainSearch)\">\n" +
    "                    <span class=\"btn-text\">Search</span> <i ng-if=\"searchLoading\" class=\"fa fa-spin fa-spinner\"></i>\n" +
    "                </button>\n" +
    "            </form>\n" +
    "            <div>\n" +
    "                <input type=\"checkbox\" ng-model=\"docsviews.selected\"> Search for Views and Documents\n" +
    "                <a class=\"pull-right\" ng-click=\"advanceSearch = !advanceSearch\">Advanced Search</a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"mms-search-input\" ng-show=\"advanceSearch\">\n" +
    "            <div class=\"misc-form-field search-nav-back\">\n" +
    "                <a ng-click=\"advanceSearch = !advanceSearch\"><i class=\"fa fa-arrow-left\"></i>Basic Search</a>\n" +
    "            </div>\n" +
    "            <!-- submit change -->\n" +
    "            <form class=\"form-inline advanced-query\" ng-submit=\"newSearch(mainSearch)\">\n" +
    "                <!-- advanced search query input disabled -->\n" +
    "                <h3 class=\"fixed-content-m\">Advanced Search</h3>\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label class=\"sr-only\" for=\"searchQuery\">Advanced search query (disabled)</label>\n" +
    "                    <input class=\"search-input disabled-input\" type=\"text\" id=\"searchQuery\" ng-model=\"stringQuery\" disabled />\n" +
    "                </div>\n" +
    "                <button class=\"btn btn-primary\" type=\"button\" ng-click=\"newSearch(mainSearch)\">\n" +
    "                    <span class=\"btn-text\">Search</span> <i ng-if=\"searchLoading\" class=\"fa fa-spin fa-spinner\"></i>\n" +
    "                </button>\n" +
    "            </form>\n" +
    "\n" +
    "            <div class=\"form-inline\" style=\"justify-content: flex-end\">\n" +
    "                <div class=\"form-group fixed-content-s\">\n" +
    "                </div>\n" +
    "                <!-- search type menu -->\n" +
    "                <div class=\"form-group fixed-content-m\">\n" +
    "                    <label class=\"sr-only\" for=\"searchTypeSelectAdvance\">Search type menu</label>\n" +
    "                    <div class=\"btn-group ve-light-dropdown\" uib-dropdown keyboard-nav is-open=\"mainSearchType.isopen2\">\n" +
    "                        <button id=\"searchTypeSelectAdvance\" class=\"dropdown-toggle\" type=\"button\" uib-dropdown-toggle>\n" +
    "                            {{mainSearch.searchType.label}}&nbsp;<i class=\"fa fa-caret-down\" aria-hidden=\"true\"></i>\n" +
    "                        </button>\n" +
    "                        <ul class=\"dropdown-menu\" uib-dropdown-menu role=\"menu\" aria-labelledby=\"searchTypeSelectAdvance\">\n" +
    "                            <li ng-click=\"mainSearch.searchType = type; stringQueryUpdate()\" ng-repeat=\"type in fieldTypeList\"\n" +
    "                                ng-class=\"{'checked-list-item': type.id === mainSearch.searchType.id}\">\n" +
    "                                <a>{{ type.label }}</a>\n" +
    "                            </li>\n" +
    "                        </ul>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <!-- search keyword input -->\n" +
    "                <div class=\"form-group\" ng-show=\"mainSearch.searchType.id != 'metatype'\">\n" +
    "                    <label class=\"sr-only\" for=\"searchText\">Advanced search query</label>\n" +
    "                    <input class=\"search-input\" type=\"text\" id=\"searchText\" ng-model=\"mainSearch.searchText\" ng-change=\"stringQueryUpdate()\" autofocus/>\n" +
    "                </div>\n" +
    "                <!-- metatype multiselect -->\n" +
    "                <div class=\"form-group\" ng-if=\"mainSearch.searchType.id === 'metatype'\">\n" +
    "                    <label class=\"sr-only\" for=\"searchMetatypeSelectAdvance\">Search metatype menu</label>\n" +
    "                    <div id=\"searchMetatypeSelectAdvance\" ng-dropdown-multiselect=\"\" options=\"metatypeList\" selected-model=\"mainSearch.selectedSearchMetatypes\" extra-settings=\"metatypeSettings\" events=\"multiselectEvent\"></div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <!-- Advanced search rows -->\n" +
    "            <div ng-repeat=\"row in advanceSearchRows\">\n" +
    "                <div class=\"form-inline\">\n" +
    "                    <!-- operator -->\n" +
    "                    <div class=\"form-group fixed-content-s\">\n" +
    "                        <label class=\"sr-only\" for=\"operator-{{$index}}\">Search row operator</label>\n" +
    "                        <div class=\"btn-group ve-light-dropdown\" uib-dropdown keyboard-nav is-open=\"row.operatorisopen\">\n" +
    "                            <button id=\"operator-{{$index}}\" class=\"dropdown-toggle\" type=\"button\" uib-dropdown-toggle>\n" +
    "                                {{row.operator}}&nbsp;<i class=\"fa fa-caret-down\" aria-hidden=\"true\"></i>\n" +
    "                            </button>\n" +
    "                            <ul class=\"dropdown-menu\" uib-dropdown-menu role=\"menu\" aria-labelledby=\"operator-{{$index}}\">\n" +
    "                                <li ng-click=\"row.operator = value; stringQueryUpdate()\" ng-repeat=\"(key, value) in operatorList\"\n" +
    "                                    ng-class=\"{'checked-list-item': value === row.operator}\">\n" +
    "                                    <a>{{ value }}</a>\n" +
    "                                </li>\n" +
    "                            </ul>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <!-- row search type menu -->\n" +
    "                    <div class=\"form-group fixed-content-m\">\n" +
    "                        <label class=\"sr-only\" for=\"searchTypeSelect-{{$index}}\">Search type menu</label>\n" +
    "                        <div class=\"btn-group ve-light-dropdown\" uib-dropdown keyboard-nav is-open=\"row.isopen\">\n" +
    "                            <button id=\"searchTypeSelect-{{$index}}\" class=\"dropdown-toggle\" type=\"button\" uib-dropdown-toggle>\n" +
    "                                {{row.searchType.label}}&nbsp;<i class=\"fa fa-caret-down\" aria-hidden=\"true\"></i>\n" +
    "                            </button>\n" +
    "                            <ul class=\"dropdown-menu\" uib-dropdown-menu role=\"menu\" aria-labelledby=\"searchTypeSelect-{{$index}}\">\n" +
    "                                <li ng-click=\"row.searchType = type; stringQueryUpdate()\" ng-repeat=\"type in fieldTypeList\"\n" +
    "                                    ng-class=\"{'checked-list-item': type.id === row.searchType.id}\">\n" +
    "                                    <a>{{ type.label }}</a>\n" +
    "                                </li>\n" +
    "                            </ul>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <!-- row search keyword input -->\n" +
    "                    <div class=\"form-group\" ng-show=\"row.searchType.id != 'metatype'\">\n" +
    "                        <label class=\"sr-only\" for=\"searchText-{{$index}}\">Advanced search query</label>\n" +
    "                        <input class=\"search-input\" type=\"text\" id=\"searchText-{{$index}}\" ng-model=\"row.searchText\" ng-change=\"stringQueryUpdate()\" autofocus/>\n" +
    "                    </div>\n" +
    "                    <!-- row metatype multiselect -->\n" +
    "                    <div class=\"form-group\" ng-if=\"row.searchType.id === 'metatype'\">\n" +
    "                        <label class=\"sr-only\" for=\"searchMetatypeSelect-{{$index}}\">Search metatype menu</label>\n" +
    "                        <div id=\"searchMetatypeSelect-{{$index}}\" ng-dropdown-multiselect=\"\" options=\"metatypeList\" selected-model=\"row.selectedSearchMetatypes\" extra-settings=\"metatypeSettings\" events=\"multiselectEvent\"></div>\n" +
    "                    </div>\n" +
    "                    <!-- remove row button -->\n" +
    "                    <a type=\"button\" ng-click=\"removeRowAdvanceSearch(row)\" class=\"btn btn-secondary\">\n" +
    "                        <i class=\"fa fa-times\"></i>\n" +
    "                    </a>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <!-- add row button -->\n" +
    "            <a type=\"button\" ng-click=\"addAdvanceSearchRow()\" class=\"btn btn-secondary\">\n" +
    "                <i class=\"fa fa-plus\"></i>&nbsp;Add Row\n" +
    "            </a>\n" +
    "            <div class=\"advanced-views-docs\">\n" +
    "                <input type=\"checkbox\" ng-model=\"docsviews.selected\"> Search for Views and Documents\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <span class=\"close-button-container\">\n" +
    "            <span class=\"close-button\" ng-if=\"mmsOptions.closeable\" ng-click=\"closeSearch()\">\n" +
    "                <i tooltip-placement=\"left\" uib-tooltip=\"Close Search\"  class=\"fa fa-times\"></i>\n" +
    "            </span>\n" +
    "        </span>\n" +
    "\n" +
    "        <!-- <div ng-show=\"advancedSearchResults\" class=\"mms-search-input\"> -->\n" +
    "            <!-- advanced search query input disabled -->\n" +
    "            <!-- <div class=\"form-group\">\n" +
    "                <div class=\"misc-form-field search-nav-back\">\n" +
    "                    <a ng-click=\"advancedSearchResults = !advancedSearchResults\"><i class=\"fa fa-arrow-left\"></i>Basic Search</a>\n" +
    "                </div>\n" +
    "                <form class=\"form-inline\" ng-submit=\"newSearch(mainSearch)\"> -->\n" +
    "                    <!-- advanced search query input disabled -->\n" +
    "                    <!-- <h3 class=\"fixed-content-m\">Advanced Search</h3>\n" +
    "                    <label class=\"sr-only\" for=\"searchText\">Advanced search query (disabled)</label>\n" +
    "                    <span ng-click=\"modifyAdvanceSearch()\" class=\"search-input disabled-input-container\">\n" +
    "                        <input class=\"search-input disabled-input form-group\" type=\"text\" id=\"searchText\" ng-model=\"stringQuery\" disabled />\n" +
    "                    </span>\n" +
    "                    <a ng-click=\"modifyAdvanceSearch()\">Modify</a>\n" +
    "                </form>\n" +
    "            </div>\n" +
    "        </div> -->\n" +
    "    </div>\n" +
    "    <div class=\"slide-animate\" ng-include=\"'mms/templates/mmsSearchResults.html'\"></div>\n" +
    "    <div class=\"container-fluid search-nav\">\n" +
    "        <a ng-show=\"currentPage > 0\" ng-click=\"prevPage()\">&lt; Prev</a>\n" +
    "        <a ng-show=\"searchResults.length > 0 && currentPage < maxPages\" class=\"pull-right\" ng-click=\"nextPage()\">Next ></a>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"container-no-results container-fluid\" ng-show=\"searchResults.length === 0 && !searchLoading\">\n" +
    "        <h3>No Results Found.</h3>\n" +
    "    </div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("mms/templates/mmsSearchResults.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsSearchResults.html",
    "<div id=\"ve-search-results\" class=\"misc-form-field results-count\" ng-hide=\"searchLoading || !paginationCache.length\">\n" +
    "    <div class=\"ve-search-filter\" ng-hide=\"!showFilterOptions\">\n" +
    "        <span class=\"label-for-filter ve-secondary-text\">FILTER: </span>\n" +
    "        <div class=\"btn-group btn-group-sm\" role=\"group\"  aria-label=\"mms-search-results-filter\">\n" +
    "            <button type=\"button\" ng-repeat=\"item in filterOptions\" ng-click=\"filterSearchResults(item.type)\"\n" +
    "            title=\"Filter {{item.display}}\" class=\"btn ve-btn-group-default {{item.icon}}\"\n" +
    "            ng-class=\"{'active': getActiveFilterClass(item.type)}\">{{item.display}}</button>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"ve-secondary-text\">Showing <b>{{searchResults.length}}</b> <!--of {{searchResults.length}} -->search results. (Page {{currentPage + 1}})</div>\n" +
    "</div>\n" +
    "\n" +
    "\n" +
    "<div class=\"search-results\" ng-show=\"searchResults.length > 0\">\n" +
    "    <div class=\"elem-wrapper\" ng-repeat=\"elem in filteredElms = (searchResults)\">\n" +
    "        <div class=\"elem-name-wrapper\">\n" +
    "            <span class=\"{{ getTypeClass(elem) }}\"></span>\n" +
    "            <a class=\"elem-name\" ng-click=\"userResultClick(elem, 'name')\">{{elem.name}}</a>\n" +
    "            <a class=\"elem-name\" ng-show=\"elem.type == 'Slot'\" ng-click=\"userResultClick(elem, 'name')\">\n" +
    "                <mms-transclude-name mms-element-id=\"{{elem.definingFeatureId}}\" mms-project-id=\"{{elem._projectId}}\" mms-ref-id=\"{{elem._refId}}\" no-click=\"true\"></mms-transclude-name>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "        <div class=\"elem-qualified-name-wrapper\">\n" +
    "            <div ng-click=\"expandQualifiedName($event, elem._qualifiedName);\" class=\"elem-qualified-name\">\n" +
    "                {{qualifiedNameFormatter(elem._qualifiedName)}}\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div ng-if=\"elem.type === 'Diagram' && elem._artifactIds\">\n" +
    "            <mms-transclude-img ng-click=\"userResultClick(elem, 'img')\" mms-element-id=\"{{elem.id}}\" mms-project-id=\"{{elem._projectId}}\" mms-ref-id=\"{{elem._refId}}\"></mms-transclude-img>\n" +
    "        </div>\n" +
    "        <div class=\"elem-documentation-wrapper\">\n" +
    "            <label>Documentation</label>\n" +
    "            <div class=\"elem-documentation\">\n" +
    "                <a ng-show=\"elem.documentation\" ng-bind-html=\"elem.documentation | limitTo:270\" ng-click=\"userResultClick(elem, 'doc')\"></a><span class=\"ellipses\">{{elem.documentation.length > 270 ? ' ...' : ''}}</span>\n" +
    "                <span><a ng-show=\"!elem.documentation\" ng-click=\"userResultClick(elem, 'doc')\">\n" +
    "                  {{emptyDocTxt}}\n" +
    "                  </a></span>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div ng-if=\"(elem.type === 'Property' || elem.type === 'Port') && elem.defaultValue\">\n" +
    "            <label>Value</label>\n" +
    "            <div class=\"elem-properties\">\n" +
    "                <a ng-click=\"userResultClick(elem, 'val')\">\n" +
    "                    {{elem.defaultValue.value + '' | limitTo:250 || elem.defaultValue.body[0] | limitTo:250 || 'Default Value'}}<span class=\"ellipses\">{{(elem.defaultValue.body[0] && elem.defaultValue.body.length > 1 || val.value.length > 250) ? ' ...' : '' }}</span>\n" +
    "                </a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div ng-if=\"elem.type === 'Slot' && elem.value\">\n" +
    "            <label>Value</label>\n" +
    "            <div class=\"elem-properties\">\n" +
    "                <a ng-click=\"userResultClick(elem, 'val')\">\n" +
    "                    <span ng-repeat=\"val in elem.value\">\n" +
    "                        {{val.value + '' | limitTo:250 || val.body[0] | limitTo:250 || 'Default Value'}}<span class=\"ellipses\">{{(val.body[0] && val.body.length > 1 || val.value.length > 250) ? ' ...' : '' }}</span>{{$last ? '' : ', '}}\n" +
    "                    </span>\n" +
    "                </a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div ng-if=\"elem.type === 'Constraint'\">\n" +
    "            <label>Specification</label>\n" +
    "            <div class=\"elem-specification\">\n" +
    "                <a ng-click=\"userResultClick(elem, 'val')\">\n" +
    "                    {{elem.specification.value + '' || 'Constraint Specification'}}\n" +
    "                </a>\n" +
    "            </div>\n" +
    "            <a ng-click=\"userResultClick(elem, 'val')\">\n" +
    "                <div ng-repeat=\"str in elem.specification.body\">\n" +
    "                    <div class=\"elem-specification\">{{str}}</div>\n" +
    "                </div>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "        <div ng-if=\"elem._properties[0]\" class=\"elem-properties-wrapper ve-search\">\n" +
    "            <label>Properties</label>\n" +
    "            <table>\n" +
    "                <tr ng-repeat=\"property in elem._properties | limitTo : limitForProps\">\n" +
    "                    <td>\n" +
    "                        {{property.name}}<span ng-if=\"property.type === 'Slot'\"><mms-transclude-name mms-element-id=\"{{property.definingFeatureId}}\" mms-project-id=\"{{elem._projectId}}\" mms-ref-id=\"{{elem._refId}}\" no-click=\"true\"></mms-transclude-name></span>: \n" +
    "                    </td>\n" +
    "                    <td>\n" +
    "                        <a ng-click=\"userResultClick(property, 'val')\">{{property.defaultValue.value | limitTo:300}}\n" +
    "                            <span ng-if=\"property.type === 'Slot'\" ng-repeat=\"val in property.value | limitTo:4\">\n" +
    "                                <span ng-bind-html=\"val.value | limitTo:300\"></span>\n" +
    "                                <span ng-bind-html=\"val.body[0] | limitTo:300\"></span>\n" +
    "                                <span ng-if=\"!val.value && !val.body[0]\">Default Value</span>\n" +
    "                            </span>\n" +
    "                            <!-- set variable for limit -->\n" +
    "                            <span ng-if=\"property.value.length > 4 || property.defaultValue.value.length > 300\">...</span>\n" +
    "                        </a>\n" +
    "                    </td>\n" +
    "                </tr>\n" +
    "                <tr class=\"visibility-toggle\" ng-show=\"elem._properties.length > 6\">\n" +
    "                    <td></td>\n" +
    "                    <td>\n" +
    "                        <a ng-click=\"showSearchResultProps = !showSearchResultProps; showSearchResultProps ? switchText='Less' : switchText='More'; showSearchResultProps ? limitForProps=50 : limitForProps=6\" ng-class=\"{active: showSearchResultProps}\">{{switchText}}</a>\n" +
    "                    </td>\n" +
    "                </tr>\n" +
    "            </table>\n" +
    "        </div>\n" +
    "        <div ng-if=\"elem.allRelatedDocuments.length > 0\" class=\"elem-related-docs-wrapper\">\n" +
    "            <label>Related Documents</label>\n" +
    "            <!-- show no more than three related views here-->\n" +
    "            <div ng-repeat=\"doc in elem.someRelatedDocuments\" class=\"elem-documentation\">\n" +
    "                <mms-view-link suppress-numbering=\"true\" mms-project-id=\"{{elem._projectId}}\" mms-ref-id=\"{{elem._refId}}\" mms-doc-id=\"{{doc.relatedDocument.id}}\" mms-element-id=\"{{doc.relatedDocument.id}}\" ng-click=\"userRelatedClick($event, doc.relatedDocument, doc.relatedDocument, elem)\"></mms-view-link>\n" +
    "                > <mms-view-link suppress-numbering=\"true\" mms-project-id=\"{{elem._projectId}}\" mms-ref-id=\"{{elem._refId}}\" mms-doc-id=\"{{doc.relatedDocument.id}}\" mms-element-id=\"{{doc.relatedView.id}}\" ng-click=\"userRelatedClick($event, doc.relatedDocument, doc.relatedView, elem)\"></mms-view-link><br/>\n" +
    "            </div>\n" +
    "\n" +
    "            <!-- show the remaining related views when users click on \"More\" -->\n" +
    "            <div ng-if=\"elem.remainingRelatedDocuments\">\n" +
    "                <div ng-repeat=\"doc in elem.remainingRelatedDocuments\" class=\"elem-documentation\">\n" +
    "                    <mms-view-link suppress-numbering=\"true\" mms-project-id=\"{{elem._projectId}}\" mms-ref-id=\"{{elem._refId}}\" mms-doc-id=\"{{doc.relatedDocument.id}}\" mms-element-id=\"{{doc.relatedDocument.id}}\" ng-click=\"userRelatedClick($event, doc.relatedDocument, doc.relatedDocument, elem)\"></mms-view-link>\n" +
    "                    > <mms-view-link suppress-numbering=\"true\" mms-project-id=\"{{elem._projectId}}\" mms-ref-id=\"{{elem._refId}}\" mms-doc-id=\"{{doc.relatedDocument.id}}\" mms-element-id=\"{{doc.relatedView.id}}\" ng-click=\"userRelatedClick($event, doc.relatedDocument, doc.relatedView, elem)\"></mms-view-link><br/>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"show-more-container\" ng-if=\"!elem.remainingRelatedDocuments && elem.allRelatedDocuments.length > elem.someRelatedDocuments.length\">\n" +
    "                <a class=\"show-more-btn\" ng-click=\"showMoreRelatedViews(elem);\"> {{(elem.allRelatedDocuments.length - elem.someRelatedDocuments.length) + ' More'}}\n" +
    "                </a>\n" +
    "            </div>\n" +
    "\n" +
    "        </div>\n" +
    "        <div ng-if=\"elem.type != 'InstanceSpecification'\">\n" +
    "            <label>Metatypes</label>\n" +
    "            <div class=\"elem-type-wrapper\">\n" +
    "                <span class=\"elem-type\">{{elem.type}}</span>\n" +
    "                <span ng-if=\"elem._appliedStereotypeIds.length\">\n" +
    "                    <span ng-repeat=\"type in elem._appliedStereotypeIds\">\n" +
    "                        <mms-transclude-name class=\"elem-type\" mms-element-id=\"{{type}}\" mms-project-id=\"{{elem._projectId}}\" mms-ref-id=\"{{elem._refId}}\" no-click=\"true\"></mms-transclude-name>\n" +
    "                    </span>\n" +
    "                </span>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"elem-updated-wrapper\">\n" +
    "            <div>Last modified {{elem._modified | date:'M/d/yy h:mm a'}} by <b>{{elem._modifier}}</b></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("mms/templates/mmsSpec.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsSpec.html",
    "<!-- HTML for edit mode -->\n" +
    "<div ng-if=\"!noEdit && editing\" class=\"editing\">\n" +
    "\n" +
    "    <h1 class=\"prop\" ng-if=\"edit.name !== undefined\"><input class=\"form-control ve-plain-input\" type=\"text\" ng-model=\"edit.name\"></h1>\n" +
    "    <span class=\"elem-updated-wrapper\">Last modified {{element._modified | date:'M/d/yy h:mm a'}} by <b>{{element._modifier}}</b></span>\n" +
    "\n" +
    "    <div ng-if=\"edit.type === 'Property' || edit.type === 'Port' || edit.type === 'Slot'\">\n" +
    "        <h2 class=\"prop-title spec-view-value-heading\">Property Value</h2>\n" +
    "        <div ng-if=\"!isEnumeration\">\n" +
    "            <div ng-if=\"editValues.length == 0\">\n" +
    "                <select ng-model=\"addValueType\" ng-options=\"key for (key, value) in addValueTypes\"></select>\n" +
    "                <button class=\"btn btn-sm btn-default\" ng-click=\"addValue(addValueType)\">Add</button>\n" +
    "            </div>\n" +
    "            <div ng-repeat=\"value in editValues\" ng-switch on=\"value.type\" ng-form=\"valForm\">\n" +
    "                <div ng-switch-when=\"LiteralInteger\" ng-class=\"{'has-error': valForm.$error.pattern}\">\n" +
    "                    <div class=\"form-inline\">\n" +
    "                    <input class=\"form-control ve-plain-input\" type=\"number\" ng-model=\"value.value\" ng-pattern=\"/^-?\\d+$/\" ng-blur=\"cleanupVal(value)\">&nbsp;\n" +
    "                    <a ng-if=\"!$first\" ng-click=\"removeVal($index)\"><i class=\"fa fa-close\"></i></a>\n" +
    "                    </div>\n" +
    "                    <label class=\"control-label mms-error-icon\" ng-show=\"valForm.$error.pattern\">Not a valid integer</label>\n" +
    "                </div>\n" +
    "                <div ng-switch-when=\"LiteralUnlimitedNatural\" ng-class=\"{'has-error': valForm.$error.pattern}\">\n" +
    "                    <div class=\"form-inline\">\n" +
    "                    <input class=\"form-control ve-plain-input\" type=\"number\" name=\"natVal\" ng-model=\"value.value\" ng-pattern=\"/^\\d+$/\" ng-blur=\"cleanupVal(value)\">&nbsp;\n" +
    "                    <a ng-if=\"!$first\" ng-click=\"removeVal($index)\"><i class=\"fa fa-close\"></i></a>\n" +
    "                    </div>\n" +
    "                    <label class=\"control-label mms-error-icon\" ng-show=\"valForm.$error.pattern\">Not a valid natural number</label>\n" +
    "                </div>\n" +
    "                <div ng-switch-when=\"LiteralBoolean\"><input type=\"checkbox\" ng-model=\"value.value\">&nbsp;{{value.value}}&nbsp;<a ng-if=\"!$first\" ng-click=\"removeVal($index)\"><i class=\"fa fa-close\"></i></a></div>\n" +
    "                <div ng-switch-when=\"LiteralReal\">\n" +
    "                    <div class=\"form-inline\">\n" +
    "                        <input class=\"form-control ve-plain-input\" type=\"number\" ng-model=\"value.value\" step=\"any\"><a ng-if=\"!$first\" ng-click=\"removeVal($index)\">&nbsp;<i class=\"fa fa-close\"></i></a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div ng-switch-when=\"LiteralString\">\n" +
    "                    <textarea ng-if=\"hasHtml(value.value)\" mms-ckeditor ng-model=\"value.value\" mms-project-id=\"{{element._projectId}}\" mms-ref-id=\"{{element._refId}}\" mms-element-id=\"{{element.id}}\" autosave-key=\"{{element._projectId + element._refId + element.id + 'index:' + $index}}\"></textarea>\n" +
    "                    <div ng-if=\"!hasHtml(value.value)\"><textarea ng-model=\"value.value\"></textarea><a ng-click=\"addHtml(value)\"><i class=\"fa fa-html5\"></i></a></div>\n" +
    "                    <a ng-if=\"!$first\" ng-click=\"removeVal($index)\"><i class=\"fa fa-close\"></i></a>\n" +
    "                </div>\n" +
    "                <div ng-switch-when=\"OpaqueExpression\">\n" +
    "                    <textarea ng-model=\"value.body[0]\"></textarea><a ng-if=\"!$first\" ng-click=\"removeVal($index)\"><i class=\"fa fa-close\"></i></a>\n" +
    "                </div>\n" +
    "                <div ng-switch-default>Editing not supported for now</div>\n" +
    "            </div>\n" +
    "            <div ng-if=\"editValues.length != 0 && isSlot\">\n" +
    "                <button class=\"btn btn-sm btn-default\" ng-click=\"addValue(editValues[0].type)\">Add</button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div ng-if=\"isEnumeration\" ng-repeat=\"val in editValues\">\n" +
    "            <select ng-model=\"val.instanceId\" ng-options=\"el.id as el.name for el in options\">\n" +
    "            </select><a ng-if=\"!$first\" ng-click=\"removeVal($index)\"><i class=\"fa fa-close\"></i></a>\n" +
    "        </div>\n" +
    "        <div ng-if=\"(isSlot || editValues.length == 0) && isEnumeration\">\n" +
    "            <button class=\"btn btn-sm btn-default\" ng-click=\"addEnumerationValue()\">Add</button>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div ng-if=\"edit.type === 'Constraint'\">\n" +
    "        <h2 class=\"prop-title spec-view-value-heading\">Constraint Specification</h2>\n" +
    "        <div ng-switch on=\"editValues[0].type\">\n" +
    "            <div ng-switch-when=\"LiteralInteger\"><input class=\"form-control ve-plain-input\" type=\"number\" ng-model=\"editValues[0].value\"></div>\n" +
    "            <div ng-switch-when=\"LiteralUnlimitedNatural\"><input class=\"form-control ve-plain-input\" type=\"number\" ng-model=\"editValues[0].value\"></div>\n" +
    "            <div ng-switch-when=\"LiteralBoolean\"><input type=\"checkbox\" ng-model=\"editValues[0].value\"></div>\n" +
    "            <div ng-switch-when=\"LiteralReal\"><input class=\"form-control ve-plain-input\" type=\"number\" ng-model=\"editValues[0].value\" step=\"any\"></div>\n" +
    "            <div ng-switch-when=\"LiteralString\">\n" +
    "                <textarea ng-model=\"editValues[0].value\"></textarea>\n" +
    "            </div>\n" +
    "            <div ng-switch-when=\"OpaqueExpression\">\n" +
    "                <textarea ng-model=\"editValues[0].body[0]\"></textarea>\n" +
    "            </div>\n" +
    "            <div ng-switch-default>Editing not supported for now</div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <h2 class=\"prop-title spec-view-doc-heading\">Documentation</h2>\n" +
    "    <textarea ng-model=\"edit.documentation\" mms-ckeditor mms-editor-api=\"editorApi\" mms-project-id=\"{{element._projectId}}\" mms-ref-id=\"{{element._refId}}\" autosave-key=\"{{element._projectId + element._refId + element.id}}\"></textarea>\n" +
    "\n" +
    "    <h2 class=\"prop-title spec-view-type-heading\">Metatypes</h2>\n" +
    "    <span class=\"elem-type-wrapper prop\">\n" +
    "        <span class=\"elem-type\">{{element.type}}</span>\n" +
    "        <div ng-repeat=\"type in element._appliedStereotypeIds\" class=\"prop elem-type\"><mms-transclude-name mms-element-id=\"{{type}}\" mms-project-id=\"{{mmsProjectId}}\" mms-ref-id=\"{{mmsRefId}}\" no-click=\"true\"></mms-transclude-name></div>\n" +
    "    </span>\n" +
    "    <h2 class=\"prop-title\">Location</h2>\n" +
    "    <span class=\"prop\">{{element._qualifiedName}}</span>\n" +
    "    <h2 class=\"prop-title\">ID</h2>\n" +
    "    <span class=\"prop id\">{{element.id}}</span>\n" +
    "    <h2 class=\"prop-title\">Branch/Tag</h2>\n" +
    "    <span class=\"prop\">{{element._refId}}</span>\n" +
    "    <h2 class=\"prop-title\">Project</h2>\n" +
    "    <span class=\"prop\">{{element._projectId}}</span>\n" +
    "    <h2 class=\"prop-title\">Formatted Modified Time</h2>\n" +
    "    <span class=\"prop\">{{element._modified}}</span>\n" +
    "    <h2 class=\"prop-title\">Commit</h2>\n" +
    "    <span class=\"prop\">{{element._commitId}}</span>\n" +
    "</div>\n" +
    "<!-- HTML for view mode -->\n" +
    "<div ng-hide=\"editing\" class=\"reading\">\n" +
    "    <p class=\"text-center\" ng-show=\"gettingSpec\"><i class=\"fa fa-3x fa-spin fa-spinner\"></i></p>\n" +
    "\n" +
    "    <div ng-hide=\"gettingSpec\">\n" +
    "        <div class=\"text-warning\" ng-if=\"mmsDisplayOldContent\">\n" +
    "            <b> Element not found:</b> displaying last found content\n" +
    "        </div>\n" +
    "        <h1 class=\"prop element-title\" ng-class=\"{'mms-error': mmsDisplayOldContent}\"><span class=\"{{ elementTypeClass }}\"></span>{{element.name}}</h1>\n" +
    "        <span class=\"elem-updated-wrapper\">Last modified {{element._modified | date:'M/d/yy h:mm a'}} by <b>{{element._modifier}}</b></span>\n" +
    "\n" +
    "        <div ng-if=\"element.type === 'Property' || element.type === 'Port' || element.type === 'Slot'\">\n" +
    "            <h2 class=\"prop-title spec-view-value-heading\">Property Value</h2>\n" +
    "            <div ng-repeat=\"value in values\" ng-switch on=\"value.type\">\n" +
    "                <span class=\"prop\" ng-switch-when=\"LiteralInteger\">{{value.value}}</span>\n" +
    "                <span class=\"prop\" ng-switch-when=\"LiteralBoolean\">{{value.value}}</span>\n" +
    "                <span class=\"prop\" ng-switch-when=\"LiteralReal\">{{value.value | veRealNum}}</span>\n" +
    "                <span class=\"prop\" ng-switch-when=\"LiteralUnlimitedNatural\">{{value.value}}</span>\n" +
    "                <span class=\"prop\" ng-switch-when=\"LiteralString\" ng-bind-html=\"value.value\"></span>\n" +
    "                <span class=\"prop\" ng-switch-when=\"ElementValue\"><mms-transclude-name mms-watch-id=\"true\" mms-element-id=\"{{value.elementId}}\" mms-project-id=\"{{mmsProjectId}}\" mms-ref-id=\"{{mmsRefId}}\"></mms-transclude-name></span>\n" +
    "                <span class=\"prop\" ng-switch-when=\"InstanceValue\"><mms-transclude-name mms-watch-id=\"true\" mms-element-id=\"{{value.instanceId}}\" mms-project-id=\"{{mmsProjectId}}\" mms-ref-id=\"{{mmsRefId}}\"></mms-transclude-name></span>\n" +
    "                <span class=\"prop\" ng-switch-when=\"OpaqueExpression\">{{value.body[0]}}</span>\n" +
    "                <span class=\"prop\" ng-switch-default>{{value}}</span>\n" +
    "            </div>\n" +
    "            <h2 class=\"prop-title spec-view-value-heading\">Property Type</h2>\n" +
    "            <span class=\"prop\" ng-if=\"element.type === 'Property'\"><mms-transclude-name mms-watch-id=\"true\" mms-element-id=\"{{element.typeId}}\" mms-project-id=\"{{mmsProjectId}}\" mms-ref-id=\"{{mmsRefId}}\" click-handler=\"propertyTypeClicked(element.typeId)\"></mms-transclude-name></span>\n" +
    "            <span class=\"prop\" ng-if=\"element.type === 'Slot'\"><mms-transclude-name mms-watch-id=\"true\" mms-element-id=\"{{element.definingFeatureId}}\" mms-project-id=\"{{mmsProjectId}}\" mms-ref-id=\"{{mmsRefId}}\" click-handler=\"propertyTypeClicked(element.definingFeatureId)\"></mms-transclude-name></span>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-if=\"element.type === 'Generalization' || element.type === 'Dependency'\">\n" +
    "            <h2 class=\"prop-title\">Source</h2>\n" +
    "            <span class=\"prop\"><mms-transclude-name mms-watch-id=\"true\" mms-element-id=\"{{element._sourceIds[0]}}\" mms-project-id=\"{{mmsProjectId}}\" mms-ref-id=\"{{mmsRefId}}\"></mms-transclude-name></span>\n" +
    "            <h2 class=\"prop-title\">Target</h2>\n" +
    "            <span class=\"prop\"><mms-transclude-name mms-watch-id=\"true\" mms-element-id=\"{{element._targetIds[0]}}\" mms-project-id=\"{{mmsProjectId}}\" mms-ref-id=\"{{mmsRefId}}\"></mms-transclude-name></span>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-if=\"element.type === 'Connector'\">\n" +
    "            <h2 class=\"prop-title\">Path 1</h2>\n" +
    "            <span class=\"prop\" ng-repeat=\"path in element._propertyPathIds[0]\"><mms-transclude-name mms-watch-id=\"true\" mms-element-id=\"{{path}}\" mms-project-id=\"{{mmsProjectId}}\" mms-ref-id=\"{{mmsRefId}}\"></mms-transclude-name></span>\n" +
    "            <h2 class=\"prop-title\">Path 2</h2>\n" +
    "            <span class=\"prop\" ng-repeat=\"path in element._propertyPathIds[1]\"><mms-transclude-name mms-watch-id=\"true\" mms-element-id=\"{{path}}\" mms-project-id=\"{{mmsProjectId}}\" mms-ref-id=\"{{mmsRefId}}\"></mms-transclude-name></span>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-if=\"element.type === 'Association'\">\n" +
    "            <h2 class=\"prop-title\">Role 1</h2>\n" +
    "            <span class=\"prop\"><mms-transclude-name mms-watch-id=\"true\" mms-element-id=\"{{element.memberEndIds[0]}}\" mms-project-id=\"{{mmsProjectId}}\" mms-ref-id=\"{{mmsRefId}}\"></mms-transclude-name></span>\n" +
    "            <h2 class=\"prop-title\">Role 2</h2>\n" +
    "            <span class=\"prop\"><mms-transclude-name mms-watch-id=\"true\" mms-element-id=\"{{element.memberEndIds[1]}}\" mms-project-id=\"{{mmsProjectId}}\" mms-ref-id=\"{{mmsRefId}}\"></mms-transclude-name></span>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-if=\"element.type === 'Constraint'\">\n" +
    "            <h2 class=\"prop-title\">Constraint Specification</h2>\n" +
    "            <div ng-switch on=\"values[0].type\">\n" +
    "                <span class=\"prop\" ng-switch-when=\"LiteralInteger\">{{values[0].value}}</span>\n" +
    "                <span class=\"prop\" ng-switch-when=\"LiteralBoolean\">{{values[0].value}}</span>\n" +
    "                <span class=\"prop\" ng-switch-when=\"LiteralReal\">{{values[0].value | veRealNum}}</span>\n" +
    "                <span class=\"prop\" ng-switch-when=\"LiteralUnlimitedNatural\">{{values[0].value}}</span>\n" +
    "                <span class=\"prop\" ng-switch-when=\"LiteralString\" ng-bind-html=\"values[0].value\"></span>\n" +
    "                <span class=\"prop\" ng-switch-when=\"ElementValue\"><mms-transclude-name mms-watch-id=\"true\" mms-element-id=\"{{value.elementId}}\" mms-project-id=\"{{mmsProjectId}}\" mms-ref-id=\"{{mmsRefId}}\"></mms-transclude-name></span>\n" +
    "                <span class=\"prop\" ng-switch-when=\"OpaqueExpression\">{{values[0].body[0]}}</span>\n" +
    "                <span class=\"prop\" ng-switch-default>{{values}}</span>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-if=\"element.type === 'Diagram' && element._artifactIds\">\n" +
    "            <mms-transclude-img mms-element-id=\"{{element.id}}\" mms-ref-id=\"{{element._refId}}\" mms-project-id=\"{{element._projectId}}\" mms-commit-id=\"{{element._commitId}}\"></mms-transclude-img>\n" +
    "        </div>\n" +
    "\n" +
    "        <h2 class=\"prop-title spec-view-doc-heading\">Documentation <a ng-click=\"showDocHTML = !showDocHTML\"><i class=\"fa fa-code\"></i></a></h2>\n" +
    "        <p ng-show=\"!showDocHTML\" class=\"doc-text\">\n" +
    "            <mms-cf mms-cf-type=\"doc\" mms-element-id=\"{{element.id}}\" mms-project-id=\"{{element._projectId}}\" mms-ref-id=\"{{element._refId}}\" mms-commit-id=\"{{element._commitId}}\" mms-watch-id=\"true\"></mms-cf>\n" +
    "        </p>\n" +
    "        <div ng-show=\"showDocHTML\">{{element.documentation}}</div>\n" +
    "\n" +
    "        <div ng-if=\"relatedDocuments.length > 0\">\n" +
    "            <h2 class=\"prop-title\">Used by Documents/Views</h2>\n" +
    "            <span class=\"elem-related-docs-wrapper prop\">\n" +
    "                <div ng-repeat=\"relatedDocument in relatedDocuments\">\n" +
    "                    <span ng-repeat=\"relatedView in relatedDocument._parentViews\">\n" +
    "                        <mms-view-link suppress-numbering=\"true\" mms-project-id=\"{{element._projectId}}\" mms-ref-id=\"{{element._refId}}\" mms-doc-id=\"{{relatedDocument.id}}\" mms-element-id=\"{{relatedDocument.id}}\" link-target=\"_blank\" rel=\"noopener\"></mms-view-link>\n" +
    "                        > <mms-view-link suppress-numbering=\"true\" mms-project-id=\"{{element._projectId}}\" mms-ref-id=\"{{element._refId}}\" mms-doc-id=\"{{relatedDocument.id}}\" mms-element-id=\"{{relatedView.id}}\" link-target=\"_blank\" rel=\"noopener\"></mms-view-link><br/>\n" +
    "                    </span>\n" +
    "                </div>\n" +
    "            </span>\n" +
    "        </div>\n" +
    "\n" +
    "        <!-- <a ng-click=\"toggleAdvancedProperties = !toggleAdvancedProperties\" class=\"visibility-toggle\" ng-class=\"{active: toggleAdvancedProperties}\" ng-init=\"toggleAdvancedProperties=false\">Advanced</a>\n" +
    "        <div ng-show=\"toggleAdvancedProperties\"> -->\n" +
    "            <h2 class=\"prop-title\">Metatypes</h2>\n" +
    "            <span class=\"elem-type-wrapper prop\">\n" +
    "                <span class=\"elem-type\">{{element.type}}</span>\n" +
    "                <div ng-repeat=\"type in element._appliedStereotypeIds\" class=\"elem-type\"><mms-transclude-name mms-element-id=\"{{type}}\" mms-project-id=\"{{mmsProjectId}}\" mms-ref-id=\"{{mmsRefId}}\" no-click=\"true\"></mms-transclude-name></div>\n" +
    "            </span>\n" +
    "\n" +
    "            <div ng-if=\"element.type === 'InstanceSpecification'\">\n" +
    "                <h2 class=\"prop-title\">Classifiers</h2>\n" +
    "                <span class=\"prop\" ng-repeat=\"path in element.classifierIds\"><mms-transclude-name mms-watch-id=\"true\" mms-element-id=\"{{path}}\" mms-project-id=\"{{mmsProjectId}}\" mms-ref-id=\"{{mmsRefId}}\"></mms-transclude-name></span>\n" +
    "            </div>\n" +
    "\n" +
    "            <h2 class=\"prop-title\">Location</h2>\n" +
    "            <span class=\"prop\">{{element._qualifiedName}}</span>\n" +
    "            <h2 class=\"prop-title\">ID&nbsp;\n" +
    "                <button ng-click=\"copyToClipboard($event, '#spec-element-id')\" class=\"btn btn-sm btn-default\" uib-tooltip=\"Copy ID\">\n" +
    "                    <i class=\"fa fa-copy\"></i>\n" +
    "                </button>\n" +
    "            </h2>\n" +
    "            <span class=\"prop id\" title=\"Copy ID\">\n" +
    "                <span id=\"spec-element-id\" ng-click=\"copyToClipboard($event, '#spec-element-id')\">{{element.id}}</span>\n" +
    "            </span>\n" +
    "            <h2 class=\"prop-title\">Branch/Tag</h2>\n" +
    "            <span class=\"prop\">{{element._refId}}</span>\n" +
    "            <h2 class=\"prop-title\">Project</h2>\n" +
    "            <span class=\"prop\">{{element._projectId}}</span>\n" +
    "            <h2 class=\"prop-title\">Formatted Modified Time</h2>\n" +
    "            <span class=\"prop\">{{element._modified}}</span>\n" +
    "            <h2 class=\"prop-title\">Commit</h2>\n" +
    "            <span class=\"prop\" title=\"View raw element data\">\n" +
    "                <a target=\"_blank\" rel=\"noopener noreferrer\" ng-href=\"{{elementDataLink}}\">\n" +
    "                    {{element._commitId}}&nbsp;<i class=\"fa fa-external-link\" aria-hidden=\"true\"></i>\n" +
    "                </a>\n" +
    "            </span>\n" +
    "        <!-- </div> -->\n" +
    "    </div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("mms/templates/mmsToolbar.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsToolbar.html",
    "<div class=\"right-toolbar\">\n" +
    "    <div class=\"toolbox\">\n" +
    "        <div ng-repeat=\"button in buttons | filter: {active: true, permission: true} | orderBy:'priority'\">\n" +
    "            <a class=\"tools {{button.id}}\"\n" +
    "               ng-class=\"{selected: button.selected, dynamic: button.dynamic, pulldown: button.pullDown}\"\n" +
    "               ng-click=\"clicked(button)\" uib-tooltip=\"{{button.tooltip}}\" tooltip-trigger=\"mouseenter\"\n" +
    "               tooltip-popup-delay=\"100\" tooltip-placement=\"left\" tooltip-append-to-body=\"true\"><i\n" +
    "                class=\"fa {{button.icon}}\"></i></a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("mms/templates/mmsTranscludeDoc.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsTranscludeDoc.html",
    "<div class=\"panel panel-default no-print\">\n" +
    "    <div class=\"panel-heading clearfix\">\n" +
    "        <h3 class=\"panel-title pull-left\">\n" +
    "            <form class=\"form-inline\">\n" +
    "            <div class=\"form-group\">\n" +
    "                <span class=\"pe-type-{{panelType}}\">{{panelType}} :</span>\n" +
    "                <span ng-if=\"!isDirectChildOfPresentationElement\">{{panelTitle}}</span>\n" +
    "                <span ng-if=\"isDirectChildOfPresentationElement\"><input type=\"text\" class=\"form-control\" ng-model=\"edit.name\"/></span>\n" +
    "            </div></form>\n" +
    "        </h3>\n" +
    "        <div class=\"btn-group pull-right\">\n" +
    "            <mms-button-bar class=\"transclude-panel-toolbar\" buttons=\"buttons\" mms-bb-api=\"bbApi\"></mms-button-bar>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"panel-body no-padding-panel\">\n" +
    "        <textarea ng-model=\"edit.documentation\" mms-ckeditor mms-editor-type=\"{{editorType}}\" mms-editor-api=\"editorApi\" mms-project-id=\"{{element._projectId}}\" mms-ref-id=\"{{element._refId}}\" autosave-key=\"{{element._projectId + element._refId + element.id}}\"></textarea>\n" +
    "    </div>\n" +
    "</div>");
}]);

angular.module("mms/templates/mmsTranscludeName.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsTranscludeName.html",
    "<form class=\"input-group\" ng-submit=\"save($event)\">\n" +
    "    <input type=\"text\" class=\"form-control\" ng-model=\"edit.name\" aria-describedby=\"basic-addon2\">\n" +
    "	<span class=\"input-group-addon transclude-name-label\">Name</span>\n" +
    "    <span class=\"input-group-addon\" ng-click=\"save($event)\" title=\"Save\">\n" +
    "        <i ng-if=\"!elementSaving\" class=\"fa fa-save\"></i>\n" +
    "        <i ng-if=\"elementSaving\" class=\"fa fa-spinner fa-spin\"></i>\n" +
    "    </span>\n" +
    "    <span class=\"input-group-addon\" ng-click=\"cancel($event)\"><i class=\"fa fa-times\" title=\"Cancel\"></i></span>\n" +
    "</form>\n" +
    "");
}]);

angular.module("mms/templates/mmsTranscludeVal.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsTranscludeVal.html",
    "<span ng-repeat=\"value in ::values\" ng-switch on=\"value.type\">\n" +
    "    <span ng-switch-when=\"LiteralInteger\">{{::value.value}}</span>\n" +
    "    <span ng-switch-when=\"LiteralBoolean\">{{::value.value}}</span>\n" +
    "    <span ng-switch-when=\"LiteralReal\">{{::value.value | veRealNum}}</span>\n" +
    "    <span ng-switch-when=\"LiteralUnlimitedNatural\">{{::value.value}}</span>\n" +
    "    <span ng-switch-when=\"ElementValue\"><mms-transclude-name no-click=\"true\" mms-element-id=\"{{::value.elementId}}\" mms-project-id=\"{{projectId}}\" mms-ref-id=\"{{refId}}\" mms-commit-id=\"{{commitId}}\"></mms-transclude-name></span>\n" +
    "    <span ng-switch-when=\"InstanceValue\"><mms-transclude-name no-click=\"true\" mms-element-id=\"{{::value.instanceId}}\" mms-project-id=\"{{projectId}}\" mms-ref-id=\"{{refId}}\" mms-commit-id=\"{{commitId}}\"></mms-transclude-name></span>\n" +
    "    <span ng-switch-when=\"OpaqueExpression\">{{::value.body[0]}}</span>\n" +
    "    <span ng-switch-default>{{::value}}</span>\n" +
    "</span>");
}]);

angular.module("mms/templates/mmsTranscludeValEdit.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsTranscludeValEdit.html",
    "<div class=\"panel panel-info\">\n" +
    "    <span ng-repeat=\"value in editValues\" ng-switch on=\"value.type\">\n" +
    "        <span ng-switch-when=\"LiteralInteger\">{{value.value}}</span>\n" +
    "        <span ng-switch-when=\"LiteralBoolean\">{{value.value}}</span>\n" +
    "        <span ng-switch-when=\"LiteralReal\">{{value.value | veRealNum}}</span>\n" +
    "        <span ng-switch-when=\"LiteralUnlimitedNatural\">{{value.value}}</span>\n" +
    "        <span ng-switch-when=\"ElementValue\"><mms-transclude-name no-click=\"true\" mms-element-id=\"{{::value.elementId}}\" mms-project-id=\"{{projectId}}\" mms-ref-id=\"{{refId}}\" mms-commit-id=\"{{commitId}}\"></mms-transclude-name></span>\n" +
    "        <span ng-switch-when=\"InstanceValue\"><mms-transclude-name no-click=\"true\" mms-element-id=\"{{::value.instanceId}}\" mms-project-id=\"{{projectId}}\" mms-ref-id=\"{{refId}}\" mms-commit-id=\"{{commitId}}\"></mms-transclude-name></span>\n" +
    "        <span ng-switch-when=\"OpaqueExpression\">{{value.body[0]}}</span>\n" +
    "        <span ng-switch-default>{{value}}</span>\n" +
    "    </span>\n" +
    "</div>");
}]);

angular.module("mms/templates/mmsTranscludeValFrame.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsTranscludeValFrame.html",
    "<!-- Most of this code is borrowed from mmsSepc.html -->\n" +
    "<div class=\"panel panel-default no-print\">\n" +
    "    <div class=\"panel-heading clearfix\">\n" +
    "        <h3 class=\"panel-title pull-left\">Value : {{element.name}}</h3>\n" +
    "        <div class=\"btn-group pull-right\">\n" +
    "            <mms-button-bar class=\"transclude-panel-toolbar\" buttons=\"buttons\" mms-bb-api=\"bbApi\"></mms-button-bar>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"panel-body\">\n" +
    "        <div ng-if=\"!isEnumeration\">\n" +
    "            <div ng-if=\"editValues.length == 0\">\n" +
    "                <select ng-model=\"addValueType\" ng-options=\"key for (key, value) in addValueTypes\"></select>\n" +
    "                <button class=\"btn btn-sm btn-default\" ng-click=\"addValue(addValueType)\">Add</button>\n" +
    "            </div>\n" +
    "            <span class=\"transclude-val\" ng-repeat=\"value in editValues\" ng-switch on=\"value.type\" ng-form=\"valForm\">\n" +
    "                <div ng-switch-when=\"LiteralInteger\" ng-class=\"{'has-error': valForm.$error.pattern}\">\n" +
    "                    <div class=\"form-inline\">\n" +
    "                    <input class=\"form-control ve-plain-input\" type=\"number\" name=\"intVal\" ng-model=\"value.value\" ng-pattern=\"/^-?\\d+$/\" ng-blur=\"cleanupVal(value)\">\n" +
    "                    <a ng-if=\"!$first\" ng-click=\"removeVal($index)\"><i class=\"fa fa-close\"></i></a>\n" +
    "                    </div>\n" +
    "                    <label class=\"control-label mms-error-icon\" ng-show=\"valForm.$error.pattern\">Not a valid integer</label>\n" +
    "                </div>\n" +
    "                <div ng-switch-when=\"LiteralUnlimitedNatural\" ng-class=\"{'has-error': valForm.$error.pattern}\">\n" +
    "                    <div class=\"form-inline\">\n" +
    "                    <input class=\"form-control ve-plain-input\" type=\"number\" name=\"natVal\" ng-model=\"value.value\" ng-pattern=\"/^\\d+$/\" ng-blur=\"cleanupVal(value)\">\n" +
    "                    <a ng-if=\"!$first\" ng-click=\"removeVal($index)\"><i class=\"fa fa-close\"></i></a>\n" +
    "                    </div>\n" +
    "                    <label class=\"control-label mms-error-icon\" ng-show=\"valForm.$error.pattern\">Not a valid natural number</label>\n" +
    "                </div>\n" +
    "                <div ng-switch-when=\"LiteralBoolean\"><input type=\"checkbox\" ng-model=\"value.value\">&nbsp;{{value.value}}&nbsp;<a ng-if=\"!$first\" ng-click=\"removeVal($index)\"><i class=\"fa fa-close\"></i></a></div>\n" +
    "                <div ng-switch-when=\"LiteralReal\">\n" +
    "                    <div class=\"form-inline\">\n" +
    "                    <input class=\"form-control ve-plain-input\" type=\"number\" ng-model=\"value.value\" step=\"any\">&nbsp;<a ng-if=\"!$first\" ng-click=\"removeVal($index)\"><i class=\"fa fa-close\"></i></a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div ng-switch-when=\"LiteralString\">\n" +
    "                    <textarea ng-if=\"hasHtml(value.value)\" mms-ckeditor mms-project-id=\"{{element._projectId}}\" mms-ref-id=\"{{element._refId}}\" autosave-key=\"{{element._projectId + element._refId + element.id + 'index:' + $index}}\" ng-model=\"value.value\"></textarea>\n" +
    "                    <div ng-if=\"!hasHtml(value.value)\"><textarea ng-model=\"value.value\"></textarea><a ng-click=\"addHtml(value)\"><i class=\"fa fa-html5\"></i></a></div>\n" +
    "                    <a ng-if=\"!$first\" ng-click=\"removeVal($index)\"><i class=\"fa fa-close\"></i></a>\n" +
    "                </div>\n" +
    "                <div ng-switch-when=\"OpaqueExpression\">\n" +
    "                    <textarea ng-model=\"value.body[0]\"></textarea><a ng-if=\"!$first\" ng-click=\"removeVal($index)\"><i class=\"fa fa-close\"></i></a>\n" +
    "                </div>\n" +
    "                <div ng-switch-default>Editing not supported for now</div>\n" +
    "            </span>\n" +
    "            <div ng-if=\"editValues.length != 0 && isSlot\">\n" +
    "                <button class=\"btn btn-sm btn-default\" ng-click=\"addValue(editValues[0].type)\">Add</button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <!-- if enum and isslot repeat values in element -->\n" +
    "        <div ng-if=\"isEnumeration\" ng-repeat=\"val in editValues\">\n" +
    "            <select ng-model=\"val.instanceId\" ng-options=\"el.id as el.name for el in options\"></select>\n" +
    "            <a ng-if=\"!$first\" ng-click=\"removeVal($index)\"><i class=\"fa fa-close\"></i></a>\n" +
    "        </div>\n" +
    "        <div ng-if=\"(isSlot || editValues.length == 0) && isEnumeration\">\n" +
    "            <button class=\"btn btn-sm btn-default\" ng-click=\"addEnumerationValue()\">Add</button>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "\n" +
    "");
}]);

angular.module("mms/templates/mmsTree.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsTree.html",
    "<ul class=\"nav nav-list nav-pills nav-stacked abn-tree\">\n" +
    "    <li ng-repeat=\"row in tree_rows | filter:{visible:true} track by row.branch.uid\" ng-hide=\"!treeFilter(row, options.search)\"\n" +
    "        ng-class=\"'level-' + {{row.level}}\" class=\"abn-tree-row\">\n" +
    "        <div class=\"arrow\" ng-click=\"user_clicks_branch(row.branch)\" ng-dblclick=\"user_dblclicks_branch(row.branch)\" ng-class=\"{'active-text': row.branch.selected}\" id=\"tree-branch-{{row.branch.data.id}}\">\n" +
    "            <div class=\"shaft\" ng-class=\"{'shaft-selected': row.branch.selected, 'shaft-hidden': !row.branch.selected}\">\n" +
    "                <a ng-href=\"{{getHref(row);}}\" class=\"tree-item\">\n" +
    "                    <i ng-class=\"{'active-text': row.branch.selected}\" ng-click=\"expandCallback(row, $event)\" class=\"indented tree-icon {{row.expand_icon}}\" ></i>\n" +
    "                    <i ng-class=\"{'active-text': row.branch.selected}\" class=\"indented tree-icon {{row.type_icon}}\" ></i>\n" +
    "                    <span class=\"indented tree-label\" ng-class=\"{'active-text': row.branch.selected}\">{{row.section}} {{row.branch.data.name}}</span>\n" +
    "                </a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </li>\n" +
    "</ul>\n" +
    "");
}]);

angular.module("mms/templates/mmsTsDiagram.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsTsDiagram.html",
    "<div class=\"tsTabPanel\" id=\"{{containerId}}\" style=\"width:95%;height:800px;\" ></div>\n" +
    "<div class=\"tsDrawingView\" id=\"{{viewId}}\" ></div>\n" +
    "<div class=\"tsTreeView\" id=\"{{controlsId}}\" ></div>\n" +
    "<div class=\"tsTableView\" id=\"{{tableId}}\" ></div>\n" +
    "<div class=\"tsTableView\" id=\"{{edgeTableId}}\" ></div>\n" +
    "<div class=\"tsInspectorView\" id=\"{{inspectorId}}\" ></div>\n" +
    "");
}]);

angular.module("mms/templates/mmsView.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsView.html",
    "<div id=\"{{mmsElementId}}\" ng-class=\"{landscape: view._printLandscape}\">\n" +
    "\n" +
    "    <h1 ng-if=\"mmsLink\" class=\"view-title\">\n" +
    "      <span class=\"ve-view-number\">{{view._veNumber}}</span> <mms-view-link ng-class=\"{'docTitle-underlined': isHover}\" mms-element-id=\"{{view.id}}\" mms-doc-id=\"{{view.id}}\"></mms-view-link>\n" +
    "      <mms-view-link class=\"open-document\" ng-mouseover=\"hoverIn()\" ng-mouseleave=\"hoverOut()\" mms-element-id=\"{{view.id}}\" mms-doc-id=\"{{view.id}}\" \n" +
    "        link-text=\"Open Document\" link-class=\"btn btn-primary no-print\" mms-external-link=\"true\" link-icon-class=\"fa fa-share\"></mms-view-link>\n" +
    "    </h1>\n" +
    "\n" +
    "    <h1 ng-if=\"!mmsLink\" class=\"view-title h{{level}}\">\n" +
    "        <span class=\"ve-view-number\">{{view._veNumber}}</span> <mms-transclude-name mms-element-id=\"{{view.id}}\" mms-project-id=\"{{view._projectId}}\" mms-ref-id=\"{{view._refId}}\"></mms-transclude-name>\n" +
    "    </h1>\n" +
    "\n" +
    "    <div class=\"ve-secondary-text last-modified no-print\">\n" +
    "      Last Modified: {{modified | date:'M/d/yy h:mm a'}} by <b>{{modifier}}</b>\n" +
    "    </div>\n" +
    "\n" +
    "    <i ng-hide=\"view\" class=\"fa fa-2x fa-spinner fa-spin\"></i>\n" +
    "\n" +
    "    <div class=\"add-pe-button-container no-print\" ng-init=\"$index = -1\" ng-include=\"'mms/templates/mmsAddPeButton.html'\"></div>\n" +
    "    <div ng-if=\"view._contents\">\n" +
    "        <!-- Cant use track by instanceVal.instance b/c of possible duplicate entries -->\n" +
    "        <div ng-repeat=\"instanceVal in view._contents.operand track by instanceVal.instanceId\"> \n" +
    "            <mms-view-presentation-elem data-mms-instance-val=\"::instanceVal\"></mms-view-presentation-elem>\n" +
    "            <div class=\"add-pe-button-container no-print\" ng-include=\"'mms/templates/mmsAddPeButton.html'\"></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div ng-if=\"view.specification\">\n" +
    "        <!-- Cant use track by instanceVal.instance b/c of possible duplicate entries -->\n" +
    "        <div ng-repeat=\"instanceVal in view.specification.operand track by instanceVal.instanceId\"> \n" +
    "            <mms-view-presentation-elem data-mms-instance-val=\"::instanceVal\"></mms-view-presentation-elem>\n" +
    "            <div class=\"add-pe-button-container no-print\" ng-include=\"'mms/templates/mmsAddPeButton.html'\"></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>");
}]);

angular.module("mms/templates/mmsViewPresentationElem.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsViewPresentationElem.html",
    "<div ng-if=\"presentationElemLoading\" class=\"fa fa-spinner fa-spin\"></div>\n" +
    "<div id=\"{{instanceSpec.id}}\" ng-if=\"!presentationElemLoading\" ng-switch on=\"presentationElem.type\">\n" +
    "    <mms-view-para class=\"read-width\" data-mms-para=\"presentationElem\" ng-switch-when=\"Paragraph\"></mms-view-para>\n" +
    "    <mms-view-table data-mms-table=\"presentationElem\" mms-pe=\"instanceSpec\" ng-switch-when=\"Table\"></mms-view-table>\n" +
    "    <mms-view-table-t class=\"read-width\" data-mms-para=\"presentationElem\" ng-switch-when=\"TableT\"></mms-view-table-t>\n" +
    "    <mms-view-list class=\"read-width\" data-mms-list=\"presentationElem\" ng-switch-when=\"List\"></mms-view-list>\n" +
    "    <mms-view-list-t class=\"read-width\" data-mms-para=\"presentationElem\" ng-switch-when=\"ListT\"></mms-view-list-t>\n" +
    "    <mms-view-img data-mms-image=\"presentationElem\" mms-pe=\"instanceSpec\" ng-switch-when=\"Image\"></mms-view-img>\n" +
    "    <mms-view-section data-mms-section=\"presentationElem\" ng-switch-when=\"InstanceSpecification\"></mms-view-section>\n" +
    "    <mms-view-equation class=\"read-width\" data-mms-para=\"presentationElem\" ng-switch-when=\"Equation\"></mms-view-equation>\n" +
    "    <mms-view-figure class=\"read-width\" data-mms-para=\"presentationElem\" ng-switch-when=\"Figure\"></mms-view-figure>\n" +
    "    <mms-view-figure class=\"read-width\" data-mms-para=\"presentationElem\" ng-switch-when=\"ImageT\"></mms-view-figure>\n" +
    "    <mms-transclude-com class=\"read-width\" mms-element-id=\"{{presentationElem.source}}\" mms-project-id=\"{{instanceSpec._projectId}}\" mms-ref-id=\"{{instanceSpec._refId}}\" ng-switch-when=\"Comment\"></mms-transclude-com>\n" +
    "    <mms-view-plot data-mms-plot=\"presentationElem\" ng-switch-when=\"Plot\"></mms-view-plot>\n" +
    "    <mms-ts-diagram mms-pe-id=\"{{instanceSpec.id}}\" mms-project-id=\"{{instanceSpec._projectId}}\" mms-ref-id=\"{{instanceSpec._refId}}\" mms-tsp-spec=\"presentationElem\" ng-switch-when=\"TomSawyerDiagram\"></mms-ts-diagram>\n" +
    "</div>");
}]);

angular.module("mms/templates/mmsViewReorder.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsViewReorder.html",
    "<!-- Nested node template -->\n" +
    "<script type=\"text/ng-template\" id=\"nodes_renderer2.html\">\n" +
    "    <div ui-tree-handle data-nodrag=\"{{element.isOpaque ? 'true' : 'false'}}\"\n" +
    "         ng-class=\"{ 'grab' : !element.isOpaque, 'no-grab': element.isOpaque }\"\n" +
    "         title=\"{{element.isOpaque ? 'Docgen element is not reorderable' : ''}}\">\n" +
    "        \n" +
    "         {{element.presentationElement.type === 'InstanceSpecification' ? 'Section' : element.presentationElement.type}} : {{element.instanceSpecification.name}}\n" +
    "    </div>\n" +
    "    <ol ui-tree-nodes ng-model=\"element.sectionElements\">\n" +
    "        <li ng-repeat=\"element in element.sectionElements\" ui-tree-node ng-include=\"'nodes_renderer2.html'\">\n" +
    "        </li>\n" +
    "    </ol>\n" +
    "</script>\n" +
    "<div class=\"container-tree-reorder\">\n" +
    "    <h4 class=\"right-pane-title\">Reorder contents</h4>\n" +
    "    <hr class=\"spec-title-divider\">\n" +
    "    <div ng-show=\"!elementReferenceTree || elementReferenceTree.length == 0\">View contents loading or unavailable\n" +
    "    </div>\n" +
    "    <div ng-show=\"elementReferenceTree && elementReferenceTree.length > 0\"><b>Bold</b> view contents are reorderable</div>\n" +
    "    <div class=\"well\" ui-tree=\"treeOptions\">\n" +
    "        <ol ui-tree-nodes class=\"root\" ng-model=\"elementReferenceTree\">\n" +
    "            <li ng-repeat=\"element in elementReferenceTree\" ui-tree-node ng-include=\"'nodes_renderer2.html'\"></li>\n" +
    "        </ol>\n" +
    "    </div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("mms/templates/mmsViewSection.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/mmsViewSection.html",
    "<div ng-if=\"section.specification\">\n" +
    "    <div ng-show=\"! isEditing\">\n" +
    "        <h1 class=\"section-title h{{level}}\" ng-hide=\"inPreviewMode\">{{section._veNumber}} {{section.name}}</h1>\n" +
    "        <div ng-show=\"inPreviewMode\"><h1 class=\"section-title h{{level}}\">{{section._veNumber}} {{edit.name}}</h1></div>\n" +
    "    </div>\n" +
    "    <div ng-class=\"{'panel panel-default' : isEditing}\">\n" +
    "        <div ng-class=\"{'no-display' : ! isEditing}\" class=\"panel-heading clearfix no-print\">\n" +
    "            <h3 class=\"panel-title pull-left\">\n" +
    "                <div ng-class=\"{prop: isEditing}\"><input class=\"form-control\" type=\"text\" ng-model=\"edit.name\"/></div>\n" +
    "            </h3>\n" +
    "            <div class=\"btn-group pull-right\">\n" +
    "                <mms-button-bar class=\"transclude-panel-toolbar\" buttons=\"buttons\" mms-bb-api=\"bbApi\"></mms-button-bar>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div ng-class=\"{'panel-body' : isEditing}\">\n" +
    "            <div class=\"add-pe-button-container no-print\" ng-init=\"$index = -1\" ng-include=\"'mms/templates/mmsAddPeButton.html'\"></div>\n" +
    "            <div ng-repeat=\"instanceVal in section.specification.operand track by instanceVal.instanceId\">\n" +
    "                <mms-view-presentation-elem data-mms-instance-val=\"::instanceVal\" data-mms-parent-section=\"::section\"></mms-view-presentation-elem>\n" +
    "                <div class=\"add-pe-button-container no-print\" ng-include=\"'mms/templates/mmsAddPeButton.html'\"></div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("mms/templates/revertConfirm.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/revertConfirm.html",
    "<div class=\"modal-header\">\n" +
    "    <h4>Revert element documentation, name, and value</h4>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-body revert-dialogue\">\n" +
    "    <p>Revert documentation, name, and value of this element from \n" +
    "    <b>{{compareCommit.commitSelected._creator}} - {{compareCommit.commitSelected._created | date:'M/d/yy h:mm a'}}</b> on {{compareCommit.ref.type}}: <b>{{compareCommit.ref.name}}</b>\n" +
    "    to \n" +
    "    <b>{{baseCommit.commitSelected._creator}} - {{baseCommit.commitSelected._created | date:'M/d/yy h:mm a'}}</b>\n" +
    "    on {{baseCommit.refSelected.type}}: <b>{{baseCommit.refSelected.name}}</b>.</p>\n" +
    "    <p>This will create a new version under your user name on <b>{{compareCommit.ref.name}}.</b></p>\n" +
    "    <p>Cross reference contents will NOT be reverted.</p>\n" +
    "\n" +
    "    <h3>Preview Element</h3>\n" +
    "    <div class=\"element-preview-box\">\n" +
    "        <h1 class=\"prop element-title\">\n" +
    "            <mms-cf mms-cf-type=\"name\" mms-element-id=\"{{mmsElementId}}\" mms-project-id=\"{{mmsProjectId}}\" mms-ref-id=\"{{baseCommit.refSelected.id}}\" mms-commit-id=\"{{baseCommit.commitSelected.id}}\"></mms-cf>\n" +
    "        </h1>\n" +
    "        <h2 class=\"prop-title spec-view-doc-heading\">Documentation</h2>\n" +
    "        <p ng-show=\"!showDocHTML\" class=\"doc-text\">\n" +
    "            <mms-cf mms-cf-type=\"doc\" mms-element-id=\"{{mmsElementId}}\" mms-project-id=\"{{mmsProjectId}}\" mms-ref-id=\"{{baseCommit.refSelected.id}}\" mms-commit-id=\"{{baseCommit.commitSelected.id}}\"></mms-cf>\n" +
    "        </p>\n" +
    "        <div ng-if=\"element.type === 'Property' || element.type === 'Port' || element.type === 'Slot'\">\n" +
    "        <h2 class=\"prop-title\">Property Value</h2>\n" +
    "        <span class=\"prop\">\n" +
    "            <mms-cf mms-cf-type=\"val\" mms-element-id=\"{{mmsElementId}}\" mms-project-id=\"{{mmsProjectId}}\" mms-ref-id=\"{{baseCommit.refSelected.id}}\" mms-commit-id=\"{{baseCommit.commitSelected.id}}\"></mms-cf>\n" +
    "        </span></div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-warning \" ng-click=\"ok()\">Revert</button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "</div>\n" +
    "");
}]);

angular.module("mms/templates/saveConflict.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("mms/templates/saveConflict.html",
    "<div class=\"modal-header\"><h4>Conflict!</h4></div>\n" +
    "<div class=\"modal-body\">\n" +
    "    <p>This element has been updated on the server since the last read\n" +
    "        and your save is rejected!</p>\n" +
    "    <hr/>\n" +
    "    <h6>Latest</h6>\n" +
    "    <mms-spec mms-type=\"element\" mms-element=\"latest\" no-edit=\"true\"></mms-spec>\n" +
    "    <hr/>\n" +
    "    <p> Do you want to discard your edits and update to the latest or force an overwrite?</p>\n" +
    "</div>\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-warning\" ng-click=\"ok()\">Discard Edits</button>\n" +
    "    <button class=\"btn btn-warning\" ng-click=\"force()\">Force Save</button>\n" +
    "    <!--<button class=\"btn btn-sm btn-primary\" ng-click=\"merge()\">Try Merge Edits</button>-->\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel (Do nothing)</button>\n" +
    "</div>\n" +
    "");
}]);
